import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';

export default function Address(props: any) {
  const { auth } = useAuth();
  const [line1, setLine1] = useState<string>('');
  const [line2, setLine2] = useState<string>('');
  const [line3, setStreet3] = useState<string>('');
  const [locality, setLocality] = useState<string>('');
  const [sublocality, setSublocality] = useState<string>('');
  const [adminstrativeAreaLevel1, setAdministrativeAreaLevel1] =
    useState<string>('');
  const [adminstrativeAreaLevel2, setAdministrativeAreaLevel2] =
    useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('US');
  const [postalCode, setPostalCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const getUserDetails = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/me/details`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      return response.data;
    };

    (async () => {
      try {
        const { address } = await getUserDetails();
        setLine1(address?.line1);
        setLine2(address?.line2);
        setLocality(address?.locality);
        setAdministrativeAreaLevel1(address?.administrative_area_level_1);
        setPostalCode(address?.postal_code);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const isDisabled = (): boolean => {
    return !line1 || !locality || !adminstrativeAreaLevel1 || !postalCode;
  };

  const setUserAddress = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const addressData = {
      line1,
      line2,
      line3, // Assuming you meant to use this instead of leaving it unused
      locality,
      sublocality, // Assuming you want to include this as well, though it's not in your form
      administrative_area_level_1: adminstrativeAreaLevel1,
      administrative_area_level_2: adminstrativeAreaLevel2, // Same as above, assuming inclusion
      country,
      country_code: countryCode,
      postal_code: postalCode,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/me/address`,
        addressData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Check response status or data to confirm successful address creation
      if (response.status === 200) {
        // Assuming 200 OK means success, adjust as needed
        navigate('/onboarding/individual/identity');
      }
    } catch (error) {
      console.error('Failed to save address:', error);
      alert('Failed to save address. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="col-lg-6 offset-lg-3 col-md-12 pb-5">
      <h5 className="mb-4">Address</h5>

      {/* Street Line 1 Field */}
      <div className="mb-3">
        <label className="form-label">Street Line 1</label>
        <input
          type="text"
          className="form-control"
          onChange={(event) => setLine1(event.target.value)}
          defaultValue={line1}
        />
      </div>

      {/* Street Line 2 Field (optional) */}
      <div className="mb-3">
        <label className="form-label">Street Line 2 (optional)</label>
        <input
          type="text"
          className="form-control"
          onChange={(event) => setLine2(event.target.value)}
          defaultValue={line2}
        />
      </div>

      <div className="row">
        <div className="col-md-5">
          <label htmlFor="inputCity">City</label>
          <input
            type="text"
            className="form-control"
            onChange={(event) => setLocality(event.target.value)}
            id="inputCity"
            defaultValue={locality}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputState">State</label>
          <select
            id="inputState"
            className="form-select"
            onChange={(event) =>
              setAdministrativeAreaLevel1(event.target.value)
            }
            value={adminstrativeAreaLevel1}
          >
            <option value=""></option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="inputZip">Zip</label>
          <input
            type="text"
            id="inputZip"
            className="form-control"
            onChange={(event) => setPostalCode(event.target.value)}
            defaultValue={postalCode}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <button
            className="btn btn-primary"
            onClick={setUserAddress}
            disabled={isDisabled()}
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
}

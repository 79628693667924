import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';
import {
  useAccount,
  usePublicClient,
  useReadContract,
  useWriteContract,
} from 'wagmi';
import { Address, encodeAbiParameters, keccak256, zeroAddress } from 'viem';
import Identity from '@capsign/contracts/artifacts/contracts/identity/Identity.sol/Identity.json';
import { useAccount as useUserAccount } from '../../contexts/AccountContext';
import { useError } from '../../contexts/ErrorContext';
import { toUtf8Bytes } from 'ethers/lib/utils';
import ClaimDetails from './ClaimDetails';

function Profile() {
  const { auth } = useAuth();
  const { account } = useUserAccount();
  const { address: accountAddress } = useAccount();
  const [address, setAddress] = React.useState('');
  const [publicKey, setPublicKey] = React.useState('');
  const [proposedAddress, setProposedAddress] = React.useState('');
  const [proposedPublicKey, setProposedPublicKey] = React.useState('');
  const [isAccredited, setIsAccredited] = React.useState(false);
  const [accreditationStatus, setAccreditationStatus] = React.useState('');
  const [files, setFiles] = React.useState<File[]>([]);
  const [signature, setSignature] = React.useState('');
  const publicClient = usePublicClient();
  const [hasClaimKey, setHasClaimKey] = React.useState(false);
  const { setError } = useError();

  const USER_IDENTITY_ADDRESS = auth?.user.identity_address as `0x${string}`;

  const CLAIM_ISSUER_ADDRESS =
    '0x9083fe914cccff76F1db174b21696d9c4ca61A87' as `0x${string}`;
  const ACCREDITED_INVESTOR_TOPIC = keccak256(
    toUtf8Bytes('ACCREDITED_INVESTOR')
  );
  const topic = ACCREDITED_INVESTOR_TOPIC;
  const scheme = BigInt(1); // ECDSA
  // const issuer = CLAIM_ISSUER_ADDRESS; // Claim issuer contract
  const issuer = USER_IDENTITY_ADDRESS;
  // const dataString = 'Investor is accredited';
  // const dataBytes = toUtf8Bytes(dataString);
  // const data = toHex(dataBytes);
  const data = '';
  const uri = 'https://example.com/claims/accredited';

  const { writeContractAsync: addClaim } = useWriteContract();
  const { writeContractAsync: addKey } = useWriteContract();

  const claimId = keccak256(
    encodeAbiParameters(
      [{ type: 'address' }, { type: 'uint256' }],
      [issuer || zeroAddress, BigInt(topic)]
    )
  );
  console.log('claimId', claimId);

  const { data: claim } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getClaim',
    abi: Identity.abi,
    args: [claimId],
  });
  console.log('claim', claim);

  const { data: managementKeys } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getKeysByPurpose',
    args: [1],
    abi: Identity.abi,
  });

  const { data: actionKeys } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getKeysByPurpose',
    args: [2],
    abi: Identity.abi,
  });

  const { data: claimKeys } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getKeysByPurpose',
    args: [3],
    abi: Identity.abi,
  });

  const { data: keyDetailsUser } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getKey',
    args: [
      keccak256(
        encodeAbiParameters([{ type: 'address' }], [accountAddress as Address])
      ),
    ],
    abi: Identity.abi,
  });

  const { data: keyDetailsIssuer } = useReadContract({
    address: USER_IDENTITY_ADDRESS,
    functionName: 'getKey',
    args: [
      keccak256(
        encodeAbiParameters(
          [{ type: 'address' }],
          ['0xac0DF551f4Ac8620E62136f3A0eA8854FB6fC6e7' as Address]
        )
      ),
    ],
    abi: Identity.abi,
  });

  React.useEffect(() => {
    if (claimKeys && (claimKeys as any[]).length > 0) {
      setHasClaimKey(true);
    } else {
      setHasClaimKey(false);
    }
  }, [claimKeys]);

  // Function to handle file uploads
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  // Function to submit accreditation proof
  const submitAccreditation = async () => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      // TODO: Individual or entity may be verified. This only works for individuals.
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/${account?.account_type === 'Business Entity' ? 'entities' : 'users'}/${auth?.user.id}/accreditation/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-ID': auth?.user.account_id,
          },
        }
      );

      if (response.status === 201) {
        setAccreditationStatus('Pending');
      }
    } catch (error) {
      console.error(error);
      alert('Failed to submit accreditation proof.');
    }
  };

  // Check if user is already accredited
  React.useEffect(() => {
    (async () => {
      try {
        // First, check onchain to see if the user is accredited
        if (claim) {
          return setIsAccredited(true);
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/${account?.account_type === 'Business Entity' ? 'business_entities' : 'users'}/${auth?.user.id}/accreditation/status`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-ID': auth?.user.account_id,
            },
          }
        );

        if (response.status === 200) {
          setAccreditationStatus(response.data.status);
          setSignature(response.data.signature);

          if (response.data.status === 'Approved') {
            // Add claim to onchain identity
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [auth, account, claim]);

  const handleAddKey = async () => {
    try {
      const claimIssuerKey = keccak256(
        // encodeAbiParameters([{ type: 'address' }], [issuer])
        encodeAbiParameters(
          [{ type: 'address' }],
          ['0xac0DF551f4Ac8620E62136f3A0eA8854FB6fC6e7' as Address]
        )
      );
      const args = [
        claimIssuerKey as `0x${string}`, // _key
        3, // _purpose
        1, // _type
      ];
      const gas = await publicClient?.estimateContractGas({
        address: USER_IDENTITY_ADDRESS,
        account: accountAddress,
        abi: Identity.abi,
        functionName: 'addKey',
        args: [claimIssuerKey as `0x${string}`, 3, 1],
      });
      const hash = await addKey({
        account: accountAddress,
        address: USER_IDENTITY_ADDRESS,
        abi: Identity.abi,
        functionName: 'addKey',
        args,
        gas,
      });

      await publicClient?.waitForTransactionReceipt({ hash });
      setHasClaimKey(true);
    } catch (error) {
      console.error('Error adding key:', error);
      setError(error);
    }
  };

  const handleAddClaim = async () => {
    try {
      if (!addClaim) {
        console.error('Contract write function is not ready.');
        return;
      }

      const args = [
        BigInt(topic),
        scheme, // CLAIM_SCHEME (1 for ECDSA)
        // issuer,
        USER_IDENTITY_ADDRESS,
        // '0xac0DF551f4Ac8620E62136f3A0eA8854FB6fC6e7',
        signature as `0x${string}`,
        data, // data
        uri, // uri
      ];
      console.log('args', args);

      const gas = await publicClient?.estimateContractGas({
        address: USER_IDENTITY_ADDRESS,
        abi: Identity.abi,
        functionName: 'addClaim',
        args,
        account: accountAddress,
      });

      const hash = await addClaim({
        address: USER_IDENTITY_ADDRESS,
        abi: Identity.abi,
        functionName: 'addClaim',
        args,
        gas,
        account: accountAddress,
      });
      await publicClient?.waitForTransactionReceipt({ hash });

      alert('Accreditation claim added to your identity!');
      setIsAccredited(true);
    } catch (error) {
      console.error('Error adding claim:', error);
      alert('Failed to add claim to your identity.');
    }
  };

  /**
   * Fetch/set user address
   */
  // React.useEffect(() => {
  //   (async () => {
  //     if (process.env.REACT_APP_REQUIRE_AUTH && auth?.user) {
  //       try {
  //         const response = await axios(`http://localhost:8080/v1/nodes/me`, {
  //           method: 'GET',
  //           headers: {
  //             Authorization: `Bearer ${auth?.token}`,
  //           },
  //         });
  //         if (response.status === 200) {
  //           if (response.data.nodes[0].address)
  //             setAddress(response.data.nodes[0].address);
  //           if (response.data.nodes[0].public_key)
  //             setPublicKey(response.data.nodes[0].public_key);
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   })();
  // }, [auth, address]);

  /**
   * Saves user address
   */
  // const onSubmit = async () => {
  //   try {
  //     const response = await axios(
  //       `${process.env.REACT_APP_API_URL}/v1/users/${auth?.user}`,
  //       {
  //         method: 'PATCH',
  //         data: {
  //           address: proposedAddress,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${auth?.token}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       setAddress(proposedAddress);
  //     }
  //   } catch (error) {
  //     const message = (error as any)?.response.data;
  //     if (message) alert(message);
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>CapSign | User Profile</title>
      </Helmet>
      <section className="card p-5 mb-5 shadow-sm mt-5">
        <div className="card-body">
          {/* <div className="row mb-4">
            <div className="col-12">
              <label htmlFor="address" className="form-label">
                Authorized Address
                <br />
                <small className="form-text text-muted">
                  This address is allowed to use CapSign (cannot be modified)
                </small>
              </label>
              {address ? (
                <input
                  className="form-control"
                  value={address}
                  readOnly
                  disabled
                />
              ) : (
                <input
                  className="form-control"
                  placeholder={''}
                  value={proposedAddress}
                  onChange={(event) => setProposedAddress(event.target.value)}
                />
              )}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <label htmlFor="publicKey" className="form-label">
                Node Public Key
                <br />
                <small className="form-text text-muted">
                  Public key associated with your CapSign node
                </small>
              </label>
              {publicKey ? (
                <input
                  className="form-control"
                  value={publicKey}
                  readOnly
                  disabled
                />
              ) : (
                <input
                  className="form-control"
                  placeholder={''}
                  value={proposedPublicKey}
                  onChange={(event) => setProposedPublicKey(event.target.value)}
                />
              )}
            </div>
          </div> */}
          <h3>Investor Accreditation</h3>
          {!hasClaimKey ? (
            <div className="alert alert-warning" role="alert">
              <p>Your identity does not have a key for claims (purpose 3).</p>
              <button onClick={handleAddKey} className="btn btn-primary">
                Add Key
              </button>
            </div>
          ) : null}
          {accreditationStatus === 'Pending' && (
            <div className="alert alert-info" role="alert">
              Your accreditation is pending review. You will receive an email
              when it is approved.
            </div>
          )}
          {isAccredited ? (
            <div className="alert alert-success" role="alert">
              You are an accredited investor.
            </div>
          ) : !['Approved', 'Pending'].includes(accreditationStatus) ? (
            <div>
              <p>
                To access certain features, please verify your accredited
                investor status. Upload the necessary documents as proof. Please
                review{' '}
                <a
                  href="https://www.sec.gov/resources-small-businesses/capital-raising-building-blocks/accredited-investors"
                  target="_blank"
                >
                  the criteria
                </a>{' '}
                for investor accreditation.
              </p>
              <div className="mb-4">
                <div className="accordion" id="accreditationAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="individualHeading">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#individualCollapse"
                        aria-expanded="true"
                        aria-controls="individualCollapse"
                      >
                        For Individuals
                      </button>
                    </h2>
                    <div
                      id="individualCollapse"
                      className="accordion-collapse collapse show"
                      aria-labelledby="individualHeading"
                      data-bs-parent="#accreditationAccordion"
                    >
                      <div className="accordion-body">
                        <ul>
                          <li>
                            <strong>Income Verification:</strong>
                            <ul>
                              <li>
                                Tax returns (e.g., IRS Form 1040) for the past 2
                                years showing income of:
                                <ul>
                                  <li>$200,000 annually (single), or</li>
                                  <li>
                                    $300,000 annually (jointly for married
                                    couples).
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Pay stubs or W-2 forms, with a written statement
                                confirming expected income.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Net Worth Verification:</strong>
                            <ul>
                              <li>
                                Assets: Bank statements, brokerage statements,
                                or real estate appraisals.
                              </li>
                              <li>
                                Liabilities: Credit reports to verify debts
                                (e.g., mortgages).
                              </li>
                              <li>
                                Must show a net worth exceeding $1 million
                                (excluding primary residence).
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Professional Credentials:</strong>
                            <ul>
                              <li>
                                Proof of licenses such as Series 7, Series 65,
                                or Series 82.
                              </li>
                              <li>
                                Verification via FINRA BrokerCheck or license
                                certificates.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="entitiesHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#entitiesCollapse"
                        aria-expanded="false"
                        aria-controls="entitiesCollapse"
                      >
                        For Entities
                      </button>
                    </h2>
                    <div
                      id="entitiesCollapse"
                      className="accordion-collapse collapse"
                      aria-labelledby="entitiesHeading"
                      data-bs-parent="#accreditationAccordion"
                    >
                      <div className="accordion-body">
                        <ul>
                          <li>
                            <strong>Financial Statements:</strong>
                            <ul>
                              <li>
                                Audited financials showing over $5 million in
                                assets.
                              </li>
                              <li>
                                Verification that the entity meets accredited
                                investor criteria.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Ownership Verification:</strong>
                            <ul>
                              <li>
                                Documents confirming all beneficial owners are
                                accredited investors (e.g., cap table, operating
                                agreement).
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>
                              Bank or Investment Account Statements:
                            </strong>
                            <ul>
                              <li>
                                These can verify total assets or holdings to
                                meet the $5 million threshold.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="thirdPartyHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#thirdPartyCollapse"
                        aria-expanded="false"
                        aria-controls="thirdPartyCollapse"
                      >
                        Letter from a Third Party
                      </button>
                    </h2>
                    <div
                      id="thirdPartyCollapse"
                      className="accordion-collapse collapse"
                      aria-labelledby="thirdPartyHeading"
                      data-bs-parent="#accreditationAccordion"
                    >
                      <div className="accordion-body">
                        <p>
                          Instead of providing personal financial documents, you
                          may submit a letter from:
                        </p>
                        <ul>
                          <li>A licensed attorney.</li>
                          <li>A CPA.</li>
                          <li>A registered investment adviser (RIA).</li>
                          <li>A broker-dealer.</li>
                        </ul>
                        <p>
                          The letter must confirm you meet the income or net
                          worth criteria and be issued within the last 90 days.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="selfCertHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#selfCertCollapse"
                        aria-expanded="false"
                        aria-controls="selfCertCollapse"
                      >
                        Self-Certification (Limited Use)
                      </button>
                    </h2>
                    <div
                      id="selfCertCollapse"
                      className="accordion-collapse collapse"
                      aria-labelledby="selfCertHeading"
                      data-bs-parent="#accreditationAccordion"
                    >
                      <div className="accordion-body">
                        <p>
                          For some 506(b) offerings, issuers may accept a signed
                          self-certification statement affirming that you meet
                          the accreditation criteria. Note that this is{' '}
                          <strong>not valid</strong> for 506(c) offerings, which
                          require stricter verification.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="accreditationFiles" className="form-label">
                  Upload Proof of Accreditation
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="accreditationFiles"
                  multiple
                  onChange={handleFileUpload}
                />
              </div>
              <button
                onClick={submitAccreditation}
                className="btn btn-primary"
                disabled={files.length === 0}
              >
                Submit
              </button>
            </div>
          ) : accreditationStatus === 'Approved' ? (
            <>
              <p>
                You have been approved. Please add the accredited investor claim
                to your onchain profile.
              </p>
              <button
                onClick={handleAddClaim}
                className="btn btn-outline-secondary"
                type="button"
              >
                Add Claim
              </button>
            </>
          ) : null}
          {/* {address ? null : (
            <div className="row">
              <div className="col-12">
                <button
                  onClick={onSubmit}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>
          )} */}
          {process.env.NODE_ENV === 'development' && (
            <ClaimDetails identityAddress={USER_IDENTITY_ADDRESS} />
          )}
        </div>
      </section>
    </>
  );
}

export default Profile;

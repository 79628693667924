import { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useOnboarding } from '../../../contexts/OnboardingContext';

export default function Details(props: any) {
  const [legalName, setLegalName] = useState('');
  const [shortName, setShortName] = useState('');
  const [entityType, setEntityType] = useState('');
  const [activityType, setActivityType] = useState('Issuer');
  const [entityOtherType, setEntityOtherType] = useState(''); // For storing 'Other' entity type

  const { auth } = useAuth();
  const { onboarding } = useOnboarding();
  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    // If it's already set, continue to next part
    if (
      onboarding?.entity?.legal_name &&
      onboarding?.entity?.short_name &&
      onboarding?.entity?.entity_type &&
      onboarding?.entity?.activity_type
    ) {
      return navigate('/onboarding/entity/registered-address');
    }

    try {
      // Create entity and entity user account
      const data = {
        legal_name: legalName,
        short_name: shortName,
        entity_type: entityType,
        activity_type: activityType,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/v1/business_entities',
        data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-ID': auth?.user.account_id,
          },
        }
      );

      navigate('/onboarding/entity/registered-address');
    } catch (error: any) {
      console.error('Error creating account:', error);
      // Handle error case, possibly setting an error state and displaying it to the user
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 pb-5" onSubmit={onSubmit}>
        <>
          <h5>Entity Details</h5>
          <Form.Group controlId="entityLegalName" className="mt-3">
            <Form.Label>Legal Name</Form.Label>
            <Form.Control
              value={onboarding?.entity?.legal_name || legalName}
              onChange={(event: any) => {
                setLegalName(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="entityShortName" className="mt-3">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              value={onboarding?.entity?.short_name || shortName}
              onChange={(event: any) => {
                setShortName(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="entitySelect" className="mt-3">
            <Form.Label>Entity Type</Form.Label>
            <Form.Select
              value={onboarding?.entity?.entity_type || entityType}
              onChange={(event: any) => setEntityType(event.target.value)}
            >
              <option value="">Please select</option>
              <option value="Corporation">C Corp</option>
              <option value="Limited Liability Company">LLC</option>
              <option value="Trust">Trust</option>
              <option value="Other" disabled>
                Other
              </option>
            </Form.Select>
          </Form.Group>

          {/* Initially only supporting investment of capital into CapSign Inc. */}

          <Form.Group controlId="activitySelect" className="mt-3">
            <Form.Label>Intended Activity</Form.Label>
            <Form.Select
              value={onboarding?.entity?.activity_type || activityType}
              onChange={(event: any) => setActivityType(event.target.value)}
            >
              <option value="">Please select</option>
              <option value="Issuer">Raise Capital</option>
              <option value="Investor">Invest in Offerings</option>
              <option value="Both">Both</option>
            </Form.Select>
          </Form.Group>
          <Form.Text className="text-muted">
            Note: We currently only support investment in CapSign Inc.
          </Form.Text>
        </>
        <Button
          variant="primary"
          className="mt-5 w-100"
          type="submit"
          disabled={
            !(
              onboarding?.entity?.legal_name && onboarding?.entity?.entity_type
            ) && !(legalName && shortName && entityType)
          }
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

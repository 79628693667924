import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { contracts } from '../../../constants';
import { parseUnits, hexToNumber, verifyTypedData, Abi, slice } from 'viem';
import { useError } from '../../../contexts/ErrorContext';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import {
  decimals,
  subscriptionToken,
  subscriptionTokenAddress,
} from '../../../utils';
import SelectBankAccount from '../../../components/SelectBankAccount';
import PDFViewer from './PDFViewer';

interface InvestModalProps {
  show: boolean;
  handleClose: () => void;
  contractAddress: string;
  offeringId: string;
  federalExemption: string;
  offeringType: string;
  onInvestmentSuccess: (investmentAmount: number) => void;
}

const InvestModal: React.FC<InvestModalProps> = ({
  show,
  handleClose,
  contractAddress,
  offeringId,
  federalExemption,
  offeringType,
  onInvestmentSuccess,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState<number | string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('ach');
  const { setError } = useError();
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync: permit } = useWriteContract();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [agreedToSubscription, setAgreedToSubscription] = useState(false);
  const [showSubscriptionAgreement, setShowSubscriptionAgreement] =
    useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionDocument, setSubscriptionDocument] = useState<Blob | null>(
    null
  );
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);

  // Function to handle opening the subscription agreement modal
  const handleShowSubscriptionModal = async () => {
    try {
      setLoadingDocument(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/subscription_agreement`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          responseType: 'arraybuffer',
        }
      );

      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });

      setSubscriptionDocument(blob);
      setLoadingDocument(false);
      setShowSubscriptionModal(true);
    } catch (error) {
      console.error('Error fetching subscription agreement:', error);
      setError('Failed to load subscription agreement.');
      setLoadingDocument(false);
    }
  };

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    setSubscriptionDocument(null);
  };

  const handleInvestmentAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvestmentAmount(e.target.value);
  };
  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  const handlePaymentMethodChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentMethod(e.target.value);
  };

  const { writeContractAsync: approve } = useWriteContract();
  const { writeContractAsync: investContract } = useWriteContract();

  const handleCryptoPayment = async () => {
    try {
      setLoading(true);
      const paymentTokenAddress = subscriptionTokenAddress[chainId];
      const tokenId = 1;
      const usdcAbi = contracts[chainId!].USDC.abi as Abi;

      // Create allowance for payment token (assuming ERC20 with permit feature)
      const name: any = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'name',
      })!;

      const nonce: any = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'nonces',
        args: [account],
      });

      // Set the domain parameters
      const domain: any = {
        name,
        // version: process.env.NODE_ENV === 'development' ? '1' : '2', // USDC uses version "2"
        version: '2',
        chainId: chainId,
        verifyingContract: paymentTokenAddress,
      };

      // Set the permit type parameters
      const types = {
        EIP712Domain: [
          {
            name: 'name',
            type: 'string',
          },
          {
            name: 'version',
            type: 'string',
          },
          {
            name: 'chainId',
            type: 'uint256',
          },
          {
            name: 'verifyingContract',
            type: 'address',
          },
        ],
        Permit: [
          {
            name: 'owner',
            type: 'address',
          },
          {
            name: 'spender',
            type: 'address',
          },
          {
            name: 'value',
            type: 'uint256',
          },
          {
            name: 'nonce',
            type: 'uint256',
          },
          {
            name: 'deadline',
            type: 'uint256',
          },
        ],
      };

      const deadline = Math.floor(Date.now() / 1000) + 3600; // 1 hour from now
      const amount = parseUnits(
        investmentAmount.toString(),
        decimals[chainId!]
      );

      let allowance = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'allowance',
        args: [account, contractAddress],
      });

      if (BigInt(allowance as any) < amount) {
        // Approve the RegD506c contract to spend USDC on behalf of the user
        const approveTxHash = await approve({
          address: paymentTokenAddress as `0x${string}`,
          abi: usdcAbi,
          functionName: 'approve',
          args: [contractAddress, amount],
          gas: BigInt(1000000),
        });

        // Wait for the approval transaction to be mined
        await publicClient?.waitForTransactionReceipt({
          hash: approveTxHash,
        });
      }

      const documentHash = '';
      const documentURI = '';

      const args = [amount, documentHash, documentURI];

      const gas = await publicClient?.estimateContractGas({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].RegD506c.abi as Abi,
        functionName: 'invest',
        args,
        account,
      });

      // Call the `invest` function on the RegD506c contract
      const txHash = await investContract({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].RegD506c.abi as Abi,
        functionName: 'invest',
        args,
        gas,
      });

      await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      // Set the permit type values
      const message = {
        owner: account,
        spender: contractAddress,
        value: amount,
        nonce,
        deadline,
      };

      // Sign the permit type data with the user's private key
      const signature: any = await walletClient?.signTypedData({
        account,
        message,
        domain,
        primaryType: 'Permit',
        types,
      });

      const [r, s, v] = [
        slice(signature, 0, 32),
        slice(signature, 32, 64),
        slice(signature, 64, 65),
      ];

      // Verify the permit type data with the signature
      const valid = await verifyTypedData({
        address: account!,
        domain,
        types,
        primaryType: 'Permit',
        message: message,
        signature,
      });

      if (!valid) {
        throw new Error('Invalid signature');
      }

      // const gas = await publicClient?.estimateContractGas({
      //   address: contractAddress as `0x${string}`,
      //   abi: contracts[chainId!].SAFE.abi as Abi,
      //   functionName: 'investWithPermit',
      //   args: [tokenId, amount, true, deadline, v, r, s],
      // });

      // const txHash = await investContract({
      //   address: contractAddress as `0x${string}`,
      //   abi: contracts[chainId!].SAFE.abi as Abi,
      //   functionName: 'investWithPermit',
      //   args: [tokenId, amount, true, deadline, v, r, s],
      //   gas,
      // });
      // console.log('txHash', txHash);

      // await publicClient?.waitForTransactionReceipt({
      //   hash: txHash,
      // });

      // Success: update offering in the database with amount invested
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/investments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            tx_hash: txHash,
            amount: Number(investmentAmount),
            token: subscriptionTokenAddress[chainId],
            token_id: tokenId,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onInvestmentSuccess(parseFloat(investmentAmount.toString()));
    } catch (error: any) {
      if (error.code === 'CALL_EXCEPTION') {
        // Decode the revert reason if available
        const errorMessage = error.data.message;
        console.log('Transaction reverted with:', errorMessage);

        // Example: Check for specific custom errors
        if (errorMessage.includes('BelowMinimumInvestment')) {
          // Handle below minimum investment error
          console.error('Investment amount is below minimum');
        } else if (errorMessage.includes('InvestmentCapExceeded')) {
          // Handle investment cap exceeded error
          console.error('Investment cap exceeded');
        } else if (errorMessage.includes('PaymentFailed')) {
          // Handle payment failed error
          console.error('Payment transfer failed');
        } else {
          // Handle unrecognized custom error
          console.error('Unrecognized custom error:', errorMessage);
        }
      }
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  /**
   * TODO: Wire payment via Plaid
   * @returns
   */
  const handleWirePayment = async () => {
    // const request: TransferAuthorizationCreateRequest = {
    //   access_token: 'access-sandbox-71e02f71-0960-4a27-abd2-5631e04f2175',
    //   account_id: '3gE5gnRzNyfXpBK5wEEKcymJ5albGVUqg77gr',
    //   type: 'debit',
    //   network: 'ach',
    //   amount: '12.34',
    //   ach_class: 'ppd',
    //   user: {
    //     legal_name: 'Anne Charleston',
    //   },
    // };
    try {
      // const response = await client.transferAuthorizationCreate(request);
      // const authorizationId = response.data.authorization.id;

      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/investments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            tx_hash: null,
            amount: Number(investmentAmount),
            token: subscriptionTokenAddress[chainId],
            token_id: null,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onInvestmentSuccess(parseFloat(investmentAmount.toString()));

      alert('Success!');
    } catch (error) {
      setError(error);
    } finally {
      handleClose();
    }
  };

  const handleAchPayment = async () => {
    try {
      alert(
        'TODO: Call backend API to tell North Capital to move external account funds'
      );
    } catch (error) {
      // handle error
    }
    return console.log('Success');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreedToSubscription || !userName) {
      // Optionally handle the error or notify the user
      return;
    }
    if (paymentMethod === 'crypto') handleCryptoPayment();
    if (paymentMethod === 'wire') handleWirePayment();
    if (paymentMethod === 'ach') handleAchPayment();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formInvestmentAmount" className="mb-3">
              <Form.Label>Investment Amount</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  value={investmentAmount}
                  onChange={handleInvestmentAmountChange}
                  required
                />
                <InputGroup.Text>
                  {paymentMethod === 'crypto'
                    ? subscriptionToken[chainId]
                    : 'USD'}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formPaymentMethod">
              <Form.Label>Payment Method</Form.Label>
              <Form.Select
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                required
              >
                <option value="ach">ACH</option>
                <option value="creditCard" disabled>
                  Credit Card
                </option>
                <option value="crypto">Crypto</option>
                <option value="wire">Wire</option>
              </Form.Select>
            </Form.Group>
            {['wire', 'ach'].includes(paymentMethod) && (
              <SelectBankAccount
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            )}
            <Form.Group controlId="formSubscriptionAgreement" className="mt-3">
              <Form.Label>
                Please review and accept the{' '}
                <a
                  className=""
                  href="#"
                  role="button"
                  onClick={handleShowSubscriptionModal}
                >
                  Subscription Agreement
                </a>
                .
                {loadingDocument && (
                  <Spinner animation="border" size="sm" className="ms-2" />
                )}
              </Form.Label>
              <Form.Check
                type="checkbox"
                // Start of Selection
                label="I hereby certify that I am an accredited investor as defined under applicable law, and I have read and agree to the Subscription Agreement"
                required
                checked={agreedToSubscription}
                onChange={(e) => setAgreedToSubscription(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId="formUserName" className="mt-3">
              <Form.Label>Please type your full name to confirm:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Full Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </Form.Group>
            <br />
            <div className="text-center">
              <Button
                variant="primary"
                size="lg"
                style={{ width: '100%' }}
                type="submit"
                className="mt-3"
                disabled={
                  loading || !selectedAccount || !investmentAmount || !userName
                }
              >
                {loading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal to display the Subscription Agreement */}
      <Modal
        show={showSubscriptionModal}
        onHide={handleCloseSubscriptionModal}
        size="lg"
        centered
        dialogClassName="modal-90w"
        aria-labelledby="subscription-agreement-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="subscription-agreement-modal">
            Subscription Agreement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '80vh' }}>
          {subscriptionDocument ? (
            <PDFViewer data={subscriptionDocument} />
          ) : (
            <div>Loading document...</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubscriptionModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvestModal;

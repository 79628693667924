import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useReadContract,
  useWriteContract,
} from 'wagmi';
import { Abi, decodeEventLog, encodeAbiParameters, zeroAddress } from 'viem';
import { contracts } from '../../../constants';
import { ethers } from 'ethers';
import { useCallsStatus, useWriteContracts } from 'wagmi/experimental';

const ReviewDraft = () => {
  const { draftId } = useParams<{ draftId: string }>();
  const { auth } = useAuth();
  const { setError } = useError();
  const [draft, setDraft] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const chainId = useChainId();

  // Share class and cap table state
  const [shareClass, setShareClass] = useState<any>();
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [capTable, setCapTable] = useState<any>();
  const [outstandingShares, setOutstandingShares] = useState<number>();
  const [membershipClass, setMembershipClass] = useState<any>();
  const [membershipClasses, setMembershipClasses] = useState<any[]>([]);
  const [safeClass, setSafeClass] = useState<any>();
  const [safeClasses, setSafeClasses] = useState<any[]>([]);
  const [identityRegistryAddress, setIdentityRegistryAddress] = useState<any>();
  const [writeContractsHash, setWriteContractsHash] = useState<any>(null);
  const { writeContractsAsync: createSafeClass } = useWriteContracts();
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });
  const [safeContractAddress, setSafeContractAddress] = useState<
    string | undefined | null
  >(undefined);

  // Contact information
  const [identity, setIdentity] = useState<any>(null);
  const [tokenAddress, setTokenAddress] = useState<any>(null);
  const [transactionHash, setTransactionHash] = useState<any>(null);

  // Contracts
  const stockContract = contracts[chainId!]?.Stock;
  const membershipContract = contracts[chainId!]?.MembershipClass;
  const safeContract = contracts[chainId!]?.SAFE;
  const identityRegistryContract = contracts[chainId!]?.IdentityRegistry;

  // Additional state variables for asset issuance
  const [tokenId, setTokenId] = useState<any>('');

  // Prepare contract write functions
  const { writeContractAsync: grant } = useWriteContract();
  const { writeContractAsync: mint } = useWriteContract();
  const { writeContractAsync: registerIdentity } = useWriteContract();
  const { writeContractsAsync: createToken } = useWriteContracts();

  // Fetch identity registry address
  useEffect(() => {
    (async () => {
      if (shareClass) {
        // Read contract data
        const identityRegistryAddress = await publicClient?.readContract({
          address: shareClass?.token_address,
          abi: stockContract?.abi as Abi,
          functionName: 'identityRegistry',
        });
        setIdentityRegistryAddress(identityRegistryAddress);
      }
      if (membershipClass) {
        // Read contract data
        const identityRegistryAddress = await publicClient?.readContract({
          address: membershipClass?.token_address,
          abi: membershipContract?.abi as Abi,
          functionName: 'identityRegistry',
        });
        setIdentityRegistryAddress(identityRegistryAddress);
      }
      if (safeClass) {
        // Read contract data
        const identityRegistryAddress = await publicClient?.readContract({
          address: safeClass?.token_address,
          abi: safeContract?.abi as Abi,
          functionName: 'identityRegistry',
        });
        setIdentityRegistryAddress(identityRegistryAddress);
      }
    })();
  }, [shareClass, safeClass, membershipClass]);

  // Fetch draft data
  useEffect(() => {
    const fetchDraft = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/drafts/${draftId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setDraft(response.data.draft);
      } catch (error: any) {
        setError(error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDraft();
  }, [draftId, auth, setError]);

  // Fetch share class and cap table
  useEffect(() => {
    (async () => {
      try {
        if (draft?.draft_type === 'Shares') {
          // Fetch share classes
          const responseShareClasses = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/share_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setShareClasses(responseShareClasses.data.result);
          setShareClass(responseShareClasses.data.result[0]);
          setTokenAddress(responseShareClasses.data.result[0].token_address);
          // Fetch cap table
          const responseCapTable = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/cap_tables/me`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setCapTable(responseCapTable.data.result);

          if (draft?.form_data) {
            // Set share class based on draft data
            const selectedShareClass = responseShareClasses.data.result.find(
              (sc: any) => sc.share_class_name === draft?.form_data?.shareClass
            );
            setShareClass(selectedShareClass);
          }
        } else if (draft?.draft_type === 'Interest') {
          // Fetch membership class
          const responseMembershipClass = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/membership_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setMembershipClasses(responseMembershipClass.data.result);
          setMembershipClass(responseMembershipClass.data.result[0]);
          setTokenAddress(responseMembershipClass.data.result[0].token_address);
        } else if (draft?.draft_type === 'SAFE') {
          // Fetch safe class
          const responseSafeClass = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/safe_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setSafeClasses(responseSafeClass.data.result);

          if (responseSafeClass.data.result.length > 0) {
            setSafeClass(responseSafeClass.data.result[0]);
            setTokenAddress(responseSafeClass.data.result[0]?.token_address);
          } else {
            // Create new SAFE class
            const terms = {
              currency: draft?.form_data?.currency || zeroAddress,
              discount: Number(draft?.form_data?.discount),
              valuationCap: Number(draft?.form_data?.valuationCap),
              uri: draft?.form_data?.uri || '',
            };
          }
        }
      } catch (error) {
        setError(error);
      }
    })();
  }, [auth?.token, draft?.form_data?.safeAddress]);

  // Fetch existing SAFE contract address from API
  useEffect(() => {
    const fetchSafeContractAddress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/tokens`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const safes = response.data.result.filter(
          (token: any) => token.asset_subtype === 'safe'
        );

        if (safes.length > 0) {
          setSafeContractAddress(safes[0].token_address);
        } else {
          setSafeContractAddress(null);
        }
      } catch (error) {
        console.error('Error fetching SAFE contract address:', error);
      }
    };
    fetchSafeContractAddress();
  }, [auth?.token]);

  // Fetch or create identity based on email
  useEffect(() => {
    if (draft?.form_data) {
      const data = draft?.form_data;
      (async () => {
        try {
          const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/identities/search?email=${data?.email}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });

          if (response.data.result) {
            setIdentity(response.data.result);
          } else {
            // Create new identity
            const createResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/v1/identities`,
              {
                email: data?.email,
                name: data?.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                  'X-Account-Id': auth?.user.account_id,
                },
              }
            );
            setIdentity(createResponse.data.result);
          }
        } catch (error) {
          setError(error);
        }
      })();
    }
  }, [auth?.token, draft?.form_data]);

  const { data: isVerified } = useReadContract({
    address: identityRegistryAddress as `0x${string}`,
    abi: identityRegistryContract?.abi as Abi,
    functionName: 'isVerified',
    args: [identity?.wallet_address],
  });

  const handleIssueAsset = async () => {
    setLoading(true);
    try {
      if (!identity) {
        return setError(`Identity not found for user ${formData?.email}`);
      }

      if (!isVerified && identityRegistryAddress) {
        // Register the identity on-chain
        const args = [
          identity.wallet_address as `0x${string}`,
          identity.identity_address as `0x${string}`,
          840, // Country code (e.g., 840 for USA)
        ];
        const gas = await publicClient?.estimateContractGas({
          account: address,
          address: identityRegistryAddress as `0x${string}`,
          abi: identityRegistryContract?.abi as Abi,
          functionName: 'registerIdentity',
          args,
        });

        await registerIdentity({
          address: identityRegistryAddress as `0x${string}`,
          abi: identityRegistryContract?.abi as Abi,
          functionName: 'registerIdentity',
          args,
          gas,
        });
      }

      const uri = 'https://api.capsign.com/v1/metadata/test.json';
      let moduleDataEncoded: string;
      let params: any[];

      if (draft?.draft_type === 'Shares') {
        // Construct the moduleData object to match ModuleDataForm structure
        const vestingSchedule = {
          totalAmount: BigInt(
            draft.form_data.moduleData.vestingSchedule.totalAmount || '0'
          ),
          startDate: BigInt(
            draft.form_data.moduleData.vestingSchedule.startDate
          ),
          cliffDuration: BigInt(
            draft.form_data.moduleData.vestingSchedule.cliffDuration
          ),
          vestingDuration: BigInt(
            draft.form_data.moduleData.vestingSchedule.vestingDuration
          ),
          vestingInterval: BigInt(
            draft.form_data.moduleData.vestingSchedule.vestingInterval
          ),
          eventVestedAmount: BigInt(0), // Adjust as necessary
        };

        const eventIds = draft.form_data.moduleData.eventIds?.map(
          (id: string) => ethers.utils.keccak256(ethers.utils.toUtf8Bytes(id))
        );
        const vestedAmounts = draft.form_data.moduleData.vestedAmounts?.map(
          (amount: string) => {
            const parsedAmount = Number(amount);
            return isNaN(parsedAmount) ? BigInt(0) : BigInt(parsedAmount);
          }
        );

        // Validate vestingSchedule fields
        if (
          isNaN(Number(vestingSchedule.totalAmount)) ||
          isNaN(Number(vestingSchedule.startDate)) ||
          isNaN(Number(vestingSchedule.cliffDuration)) ||
          isNaN(Number(vestingSchedule.vestingDuration)) ||
          isNaN(Number(vestingSchedule.vestingInterval))
        ) {
          throw new Error('Invalid vesting schedule data.');
        }

        // Encode the module data
        const moduleData = {
          vestingSchedule: {
            totalAmount: vestingSchedule.totalAmount,
            startDate: vestingSchedule.startDate,
            cliffDuration: vestingSchedule.cliffDuration,
            vestingDuration: vestingSchedule.vestingDuration,
            vestingInterval: vestingSchedule.vestingInterval,
            eventVestedAmount: vestingSchedule.eventVestedAmount,
          },
          eventIds: eventIds,
          vestedAmounts: vestedAmounts,
        };

        moduleDataEncoded = encodeAbiParameters(
          [
            {
              type: 'tuple',
              components: [
                {
                  type: 'tuple',
                  name: 'vestingSchedule',
                  components: [
                    { type: 'uint256', name: 'totalAmount' },
                    { type: 'uint256', name: 'startDate' },
                    { type: 'uint256', name: 'cliffDuration' },
                    { type: 'uint256', name: 'vestingDuration' },
                    { type: 'uint256', name: 'vestingInterval' },
                    { type: 'uint256', name: 'eventVestedAmount' },
                  ],
                },
                { type: 'bytes32[]', name: 'eventIds' },
                { type: 'uint256[]', name: 'vestedAmounts' },
              ],
            },
          ],
          [moduleData]
        );

        params = [
          identity.wallet_address,
          vestingSchedule.totalAmount,
          uri,
          moduleDataEncoded,
        ];

        // Estimate gas and send transaction for stock grant
        const gas = await publicClient?.estimateContractGas({
          args: params,
          address: tokenAddress as `0x${string}`,
          abi: stockContract?.abi as Abi,
          functionName: 'grant',
          account: address,
        });

        const txResponse = await grant({
          address: tokenAddress as `0x${string}`,
          abi: stockContract?.abi as Abi,
          functionName: 'grant',
          args: params,
          gas,
        });

        // Wait for transaction receipt
        const txReceipt = await publicClient?.waitForTransactionReceipt({
          hash: txResponse,
        });
        // Handle tokenId from event logs
        const tokenId = extractTokenIdFromReceipt(txReceipt, 'StockGranted');
        setTokenId(tokenId);
        setTransactionHash(txResponse);
      } else if (draft?.draft_type === 'Interest') {
        // Prepare moduleData for fund/SPV allocation
        moduleDataEncoded = '0x'; // Replace with actual encoding if needed

        const formData = draft?.form_data;
        const amountInEther = formData?.allocationAmount;
        params = [
          identity.wallet_address,
          amountInEther,
          uri,
          moduleDataEncoded,
          new Date().getTime() + 1000 * 60 * 60 * 24 * 30, // 30 days from now
        ];

        // Estimate gas and send transaction for allocation
        const gas = await publicClient?.estimateContractGas({
          args: params,
          address: tokenAddress as `0x${string}`,
          abi: contracts[chainId].MembershipClass.abi as Abi,
          functionName: 'allocate',
          account: address,
        });

        const txResponse = await mint({
          address: tokenAddress as `0x${string}`,
          abi: contracts[chainId].MembershipClass.abi as Abi,
          functionName: 'allocate',
          args: params,
          gas,
        });

        // Wait for transaction receipt
        const txReceipt = await publicClient?.waitForTransactionReceipt({
          hash: txResponse,
        });

        // Handle tokenId from event logs
        const tokenId = extractTokenIdFromReceipt(txReceipt, 'Transfer');
        setTokenId(Number(tokenId));
        return { txHash: txResponse, tokenId: Number(tokenId) };
      } else if (draft?.draft_type === 'SAFE') {
        // Create new SAFE token
        const terms = {
          currency: formData.currency || zeroAddress,
          discount: Number(formData.discount),
          valuationCap: Number(formData.valuationCap),
          uri: formData.uri || '',
        };
        console.log('terms', terms);
        const classId = await publicClient?.readContract({
          address: safeContractAddress as `0x${string}`,
          abi: contracts[chainId].SAFE.abi as Abi,
          functionName: 'getSafeClassId',
          args: [terms],
        });

        console.log('checking isAgent');
        const isAgent = await publicClient?.readContract({
          address: safeContractAddress as `0x${string}`,
          abi: contracts[chainId].SAFE.abi as Abi,
          functionName: 'isAgent',
          args: [address],
        });
        console.log('isAgent', isAgent);

        console.log('calling gas estimate for issue');
        const args = [
          identity.wallet_address,
          BigInt(50_000),
          uri,
          classId,
          '0x',
        ];
        console.log('args', args);
        const gasEstimate = await publicClient?.estimateContractGas({
          address: safeContractAddress as `0x${string}`,
          abi: contracts[chainId].SAFE.abi as Abi,
          functionName: 'issue',
          args,
          account: address,
        });
        console.log('gasEstimate', gasEstimate);

        const calls = [
          {
            address: safeContractAddress as `0x${string}`,
            abi: contracts[chainId].SAFE.abi as Abi,
            functionName: 'issue',
            args,
          },
        ];

        if (classId === BigInt(0)) {
          calls.unshift({
            address: safeContractAddress as `0x${string}`,
            abi: contracts[chainId].SAFE.abi as Abi,
            functionName: 'createSafeClass',
            args: [terms],
          });
        }
        console.log('address', address);
        console.log('calls', calls);
        const gas = await publicClient?.estimateContractGas(calls[0]);
        console.log('gas', gas);
        const writeContractsHash = await createToken({
          contracts: calls,
          account: address,
          chainId,
        });
        console.log('writeContractsHash', writeContractsHash);
      }
    } catch (e: any) {
      setError(e.message);
      console.error(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSafeClass = async () => {
    try {
      if (!safeContractAddress) {
        return setError('SAFE contract address is required');
      }
      // Create the new Safe class if necessary
      const terms = {
        currency: formData.currency || zeroAddress,
        discount: Number(formData.discount),
        valuationCap: Number(formData.valuationCap),
        uri: formData.uri || '',
      };
      const classId = await publicClient?.readContract({
        address: safeContractAddress as `0x${string}`,
        abi: contracts[chainId!].SAFE.abi as Abi,
        functionName: 'getSafeClassId',
        args: [terms],
      });
      console.log('classId', classId);

      if (classId === BigInt(0)) {
        const calls = [
          {
            address: safeContractAddress as `0x${string}`,
            abi: contracts[chainId!].SAFE.abi as Abi,
            functionName: 'createSafeClass',
            args: [terms],
          },
        ];
        const result = await createSafeClass({
          contracts: calls,
          chainId,
          account: address,
        });
        setWriteContractsHash(result as any);
      }

      return classId;
    } catch (error: any) {
      setError(error.response?.data?.message || error.message);
      console.error(error);
    }
  };

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash) return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  useEffect(() => {
    if (result && result?.status === 'CONFIRMED') {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter(
            (l: any) =>
              l.address === contracts[chainId!].SAFE.address.toLowerCase()
          );
          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].SAFE.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'SafeClassCreated'
          )[0];

          if (!event) {
            console.error('SafeClassCreated event not found.');
            return;
          }

          const { _token: tokenAddress, _ir: ir } = event.args as any;

          // TODO: Save token_address and ir
          console.log('tokenAddress', tokenAddress);
          console.log('ir', ir);

          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/safe_classes`,
            method: 'POST',
            data: {
              currency: zeroAddress,
              discount: formData.discount,
              valuation_cap: formData.valuationCap,
              uri: formData.uri,
              token_address: tokenAddress,
              ir,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
        } catch (error) {
          console.error('Error tokenizing:', error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [result]);

  const extractTokenIdFromReceipt = (
    receipt: any,
    eventName: string
  ): number | null => {
    const eventAbiItem: any = stockContract?.abi.find(
      (item: any) => item.name === eventName
    );

    if (receipt?.logs) {
      for (const log of receipt.logs) {
        try {
          const event = decodeEventLog({
            abi: [eventAbiItem],
            eventName,
            data: log.data,
            topics: log.topics,
          });
          const tokenId = (event?.args as any)?.tokenId;
          return Number(tokenId);
        } catch (error) {
          // Not the event we are looking for
        }
      }
    }
    console.log('No tokenId found');
    return null;
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const classId = await handleCreateSafeClass();
      if (classId !== BigInt(0)) {
        await handleIssueAsset();
      }
    } catch (error: any) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (transactionHash) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/drafts/${draftId}/confirm`,
          {
            tx_hash: transactionHash,
            token_id: Number(tokenId),
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        alert('Asset issued successfully!');
        navigate('/equity');
      }
    })();
  }, [transactionHash]);

  let formData = draft?.form_data || '{}';

  // Function to render field values
  const renderFieldValue = (key: string, value: any) => {
    if (value === null || value === undefined || value === '') {
      return <span className="text-muted">Not provided</span>;
    } else if (key === 'moduleData') {
      // Format moduleData using JSON.stringify with indentation
      return (
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      );
    } else if (typeof value === 'object') {
      return (
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      );
    } else {
      return value;
    }
  };

  // Display the draft details for review
  return (
    <Container className="my-5">
      <h2>Review Draft</h2>
      <p>Please review the details below before confirming.</p>

      {/* Render different summaries based on draft_type */}
      <Table striped bordered hover>
        <tbody>
          {Object.keys(formData).length > 0 ? (
            Object.keys(formData).map((key) => {
              const value = formData[key];
              return (
                <tr key={key}>
                  <td style={{ textTransform: 'capitalize', width: '30%' }}>
                    {key.replace(/([A-Z])/g, ' $1')}
                  </td>
                  <td>{renderFieldValue(key, value)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2}>No data available.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Button
        variant="primary"
        onClick={() => navigate(`/equity/drafts/${draftId}/edit`)}
        className="me-2"
      >
        Back
      </Button>
      <Button variant="success" onClick={handleConfirm} disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Confirm'}
      </Button>
    </Container>
  );
};

export default ReviewDraft;

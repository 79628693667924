import { http, createConfig } from 'wagmi';
import { base, baseSepolia, Chain, hardhat } from 'wagmi/chains';
import { coinbaseWallet } from 'wagmi/connectors';

// Customize hardhat chain for development
const localHardhat = {
  ...hardhat,
  id: 31337,
  name: 'Hardhat Local',
  network: 'hardhat',
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['http://localhost:8545'] },
    public: { http: ['http://localhost:8545'] },
  },
};

const chains = (
  process.env.NODE_ENV === 'development'
    ? [localHardhat, base, baseSepolia]
    : [base, baseSepolia]
) as readonly [Chain, ...Chain[]];

export const config = createConfig({
  chains,
  connectors: [
    coinbaseWallet({
      appName: 'CapSign',
      appLogoUrl: 'https://capsign.com/apple-icon.png',
      preference: 'smartWalletOnly',
      version: '4',
    }),
  ],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http(),
    [localHardhat.id]: http('http://localhost:8545'),
  },
});

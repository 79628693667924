import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { contracts, formatOptions } from '../../constants';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import numbro from 'numbro';
import { maxBlockRange } from '../../utils';
import { useReadContract, useChainId, usePublicClient } from 'wagmi';
import { Abi, zeroAddress } from 'viem';
import ManageCertificateForm from './ManageCertificateForm';
import ManageSafeForm from './ManageSafeForm';
import { Spinner } from 'react-bootstrap';
import VestingActionForm from './VestingActionForm';

export default function ViewStakeholder() {
  const { auth } = useAuth();
  const { setError } = useError();
  const { tokenId, identityId } = useParams();
  const [identity, setIdentity] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [securityType, setSecurityType] = useState('Stock Certificate');
  const [issuances, setIssuances] = useState<any[]>([]);
  const [cancellations, setCancellations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const location = useLocation();

  const tokenContract = contracts[chainId!]?.Stock;

  const handleSecurityTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSecurityType(e.target.value);
  };

  const { data: balance } = useReadContract({
    address: location.state.shareClass.token_address as `0x${string}`,
    abi: tokenContract.abi as Abi,
    functionName: 'balanceOf',
    args: [identity?.wallet_address],
  });

  /**
   * Fetch security transactions
   */
  useEffect(() => {
    (async () => {
      try {
        if (identity?.wallet_address) {
          const tokenAddress = tokenId as `0x${string}`;

          // Get StockGranted event ABI
          const transferEventAbiItem: any = tokenContract.abi.find(
            (item: any) => item.name === 'StockGranted'
          )!;

          const latestBlock = await publicClient?.getBlockNumber();
          let fromBlock = latestBlock! - BigInt(maxBlockRange[chainId!]);

          const issueEvents: any = await publicClient?.getLogs({
            address: tokenAddress,
            event: transferEventAbiItem,
            args: {
              to: identity?.wallet_address as `0x${string}`,
            },
            fromBlock,
            toBlock: latestBlock,
          });
          setIssuances(issueEvents);

          const cancelEvents: any = await publicClient?.getLogs({
            address: tokenAddress,
            event: transferEventAbiItem,
            args: {
              from: identity?.wallet_address,
              to: zeroAddress,
            },
            fromBlock,
            toBlock: latestBlock,
          });
          setCancellations(cancelEvents);
        }
      } catch (error) {
        setError(error);
        console.log(error);
      }
    })();
  }, [identity?.wallet_address, chainId, publicClient, tokenContract.abi]);

  /**
   * Fetch identity
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/v1/identities/${identityId}`,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setIdentity(response.data.result);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [auth?.token, identityId]);

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink
                  to={`/equity/${location.state?.shareClass.class_id}`}
                  state={location.state}
                >
                  {location.state?.shareClass.class_name}
                </NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink
                  to={`/equity/${location.state?.shareClass.class_id}/identities`}
                  state={location.state}
                >
                  Permissions
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {identity?.legal_name}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Row className="py-5">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4>Stakeholder Wallets</h4>
            <div>
              {numbro(balance?.toString()).format(formatOptions)} shares
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>{identity?.wallet_address}</div>
                <Button onClick={() => setShowModal(true)} disabled={loading}>
                  {loading ? <Spinner size="sm" /> : 'Manage'}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={{ span: 8, offset: 2 }}>
          <VestingActionForm />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <h5>Issuance History</h5>
          {issuances.map((event: any) => {
            return (
              <div
                key={event.transactionHash}
                className="d-flex justify-content-between align-items-center border-bottom py-3"
              >
                <div>
                  <span className="text-muted">Token ID</span>
                  <br />
                  {event?.args?.tokenId?.toString()}
                </div>
                <div>
                  <span className="text-muted">Block</span>
                  <br />
                  {event.blockNumber.toString()}
                </div>
                <div>
                  <span className="text-muted">Quantity</span>
                  <br />
                  {numbro(event?.args?.amount?.toString()).format(
                    formatOptions
                  )}
                </div>
              </div>
            );
          })}
          {issuances.length === 0 && (
            <span className="text-muted">No issuance history.</span>
          )}
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h5>Cancelation History</h5>
          {cancellations.map((event: any) => {
            return (
              <div
                key={event.transactionHash}
                className="d-flex justify-content-between align-items-center border-bottom py-3"
              >
                <div>
                  <span className="text-muted">Token ID</span>
                  <br />
                  {event?.args?.tokenId?.toString()}
                </div>
                <div>
                  <span className="text-muted">Block</span>
                  <br />
                  {event.blockNumber.toString()}
                </div>
                <div>
                  <span className="text-muted">Quantity</span>
                  <br />
                  {numbro(event?.args?.amount?.toString()).format(
                    formatOptions
                  )}
                </div>
              </div>
            );
          })}
          {cancellations.length === 0 && (
            <span className="text-muted">No cancellation history.</span>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manage Securities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Security Type</Form.Label>
            <Form.Select
              value={securityType}
              onChange={handleSecurityTypeChange}
            >
              <option value="Stock Certificate">Stock Certificate</option>
              <option value="SAFE">SAFE</option>
            </Form.Select>
          </Form.Group>
          {securityType === 'Stock Certificate' && (
            <ManageCertificateForm
              setShowModal={setShowModal}
              showModal={showModal}
              identity={identity}
            />
          )}
          {securityType === 'SAFE' && <ManageSafeForm />}
        </Modal.Body>
      </Modal>
    </>
  );
}

import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';

export default function VerifyEmail() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setError } = useError();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  /**
   * Verify the email if token exists
   */
  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/users/me/verify_email`,
            method: 'POST',
            data: { token },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-ID': auth?.user.account_id,
            },
          });

          // Email verified, continue with onboarding
          if (response.status === 200) {
            navigate('/onboarding/account/type');
          }
        }
      } catch (error: any) {
        console.error(error);
        setError(error.response.data);
      } finally {
      }
    })();
  }, []);

  return (
    <div className="vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
        <div className="col-lg-4 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {/* <img
                      src={logo}
                      alt="CapSign"
                      className="img-fluid"
                    /> */}
                    <h1 className="my-4">Verify Email</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-2 col-8">
                    <hr />
                  </div>
                </div>
                {token && (
                  <div className="row mt-5 mb-3">
                    <div className="col-lg-6 offset-lg-3 text-center py-3">
                      <i className="fas fa-spin fa-spinner fa-2x" />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Form,
  Button,
  Table,
  Container,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import { contracts } from '../../../constants';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
import { useChainId, useWriteContract } from 'wagmi';
import { formatBytes32String } from 'ethers/lib/utils';
import { useEntity } from '../../../contexts/EntityContext';

export default function EditClass() {
  const uuid = uuidv4();
  const { auth } = useAuth();
  const { entity } = useEntity();
  const chainId = useChainId();
  const location = useLocation();
  const { setError } = useError();
  const [shareClass, setShareClass] = useState<any[]>([]);
  const [classType, setClassType] = useState(
    location.state?.shareClass.class_type
  );
  const [uri, setUri] = useState(
    location.state?.shareClass.metadata?.uri || ''
  );
  const [className, setClassName] = useState(
    location.state?.shareClass.class_name
  );
  const [submitting, setSubmitting] = useState(false);
  const [color, setColor] = useState(location.state.shareClass.color);
  const [prefix, setPrefix] = useState(location.state?.shareClass.prefix);
  const [boardApprovalDate, setBoardApprovalDate] = useState(
    location.state?.shareClass?.board_approval_date
  );
  const [stockholderApprovalDate, setStockholderApprovalDate] = useState(
    location.state?.shareClass.stockholder_approval_date
  );
  const [initialSharesAuthorized, setInitialSharesAuthorized] =
    useState<number>(location.state?.shareClass.initial_shares_authorized);
  const [votesPerShare, setVotesPerShare] = useState(1);
  const [parValue, setParValue] = useState<number>(
    location.state?.shareClass.par_value
  );
  const [pricePerShare, setPricePerShare] = useState<number>();
  const [seniority, setSeniority] = useState<number>();
  const [liquidationPreferenceMultiple, setLiquidationPreferenceMultiple] =
    useState<number>();
  const [participationCapMultiple, setParticipationCapMultiple] =
    useState<number>();

  const contract = contracts[chainId!].Stock;

  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/share_classes/${location.state?.shareClass?.class_id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setShareClass(response.data.result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const lettersOnly = value.replace(/[^a-zA-Z]/g, ''); // Allow only alphabetical characters
    setPrefix(lettersOnly);
  };

  const updateShareClass = () => {};

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink
                  to={`/equity/${location.state?.shareClass?.class_id}`}
                  state={location.state}
                >
                  {location.state?.shareClass?.class_name}
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Row className="justify-content-md-center my-5">
        <Col md={8}>
          <Form onSubmit={updateShareClass}>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formShareClass" className="my-3">
                  <Form.Label>Share Class</Form.Label>
                  <Form.Select
                    value={classType}
                    onChange={(e) => setClassType(e.target.value)}
                    required
                  >
                    <option value="Common">Common</option>
                    <option value="Preferred">Preferred</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formSubtype" className="my-3">
                  <Form.Label>
                    Subtype <small className="text-muted">(optional)</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                    placeholder="Class A, Series Seed, etc."
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group controlId="formColor" className="my-3">
                  <Form.Label>Color</Form.Label>
                  <Form.Control
                    type="color"
                    value={color}
                    title="Choose your color"
                    onChange={(e) => setColor(e.target.value)}
                    required
                  />
                  <small className="text-secondary">
                    For display in charts.
                  </small>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group controlId="formPrefix" className="my-3">
                  <Form.Label>Class Prefix</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={prefix}
                      onChange={handlePrefixChange}
                      required
                    />
                    <InputGroupText>-</InputGroupText>
                  </InputGroup>
                  <small className="text-secondary">E.g., "CS-"</small>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formBoardApprovalDate" className="my-3">
                  <Form.Label>Board Approval Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={boardApprovalDate}
                    onChange={(e) => setBoardApprovalDate(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  controlId="formStockholderApprovalDate"
                  className="my-3"
                >
                  <Form.Label>Stockholder Approval Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={stockholderApprovalDate}
                    onChange={(e) => setStockholderApprovalDate(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  controlId="formInitialSharesAuthorized"
                  className="my-3"
                >
                  <Form.Label>Initial Shares Authorized</Form.Label>
                  <Form.Control
                    type="number"
                    value={
                      initialSharesAuthorized === 0
                        ? ''
                        : initialSharesAuthorized
                    }
                    onChange={(e) =>
                      setInitialSharesAuthorized(Number(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formVotesPerShare" className="my-3">
                  <Form.Label>Votes Per Share</Form.Label>
                  <Form.Control
                    type="number"
                    value={votesPerShare}
                    required
                    onChange={(e) => setVotesPerShare(Number(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formParValue" className="my-3">
                  <Form.Label>Par Value</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      step="0.00001"
                      value={parValue}
                      placeholder={'0.01'}
                      onChange={(e) => setParValue(Number(e.target.value))}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formPricePerShare" className="my-3">
                  <Form.Label>Original Issue Price</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      step="0.00001"
                      required
                      placeholder={'0.01'}
                      value={pricePerShare}
                      onChange={(e) => setPricePerShare(Number(e.target.value))}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formSeniority" className="my-3">
                  <Form.Label>Seniority</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    onChange={(e) => setSeniority(Number(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  controlId="formLiquidationPreference"
                  className="my-3"
                >
                  <Form.Label>Liquidation Preference Multiple</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    required
                    value={liquidationPreferenceMultiple}
                    onChange={(e) =>
                      setLiquidationPreferenceMultiple(Number(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  controlId="formParticipationCapMultiple"
                  className="my-3"
                >
                  <Form.Label>Participation Cap Multiple</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    required
                    value={participationCapMultiple}
                    onChange={(e) =>
                      setParticipationCapMultiple(Number(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formUri" className="my-3">
                  <Form.Label>Metadata URI</Form.Label>
                  <Form.Control
                    type="text"
                    value={uri}
                    readOnly={true}
                    disabled
                    onChange={(e) => setUri(e.target.value)}
                  />
                  <small className="text-secondary">
                    This is a JSON file (official schema TBD) that contains
                    details that apply to the whole share class.
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" disabled={submitting}>
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { Card, Container, Button, Row, Col, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { contracts } from '../../../constants';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
} from 'wagmi';
import { ethers } from 'ethers';
import {
  Abi,
  AbiFunction,
  concat,
  encodeAbiParameters,
  keccak256,
  parseEther,
  parseUnits,
  zeroAddress,
} from 'viem';

const baseSepoliaCreate2Deployer = '0x914d7Fec6aaC8cd542e72Bca78B30650d45643d7';

const ReviewOffering: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const [offering, setOffering] = useState<any>(null);
  const [safeTerms, setSafeTerms] = useState<any>(null);
  const [documents, setDocuments] = useState<any[]>([]);
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const chainId = useChainId();
  const { address } = useAccount();

  useEffect(() => {
    const fetchOfferingDetails = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setOffering(response.data.result);
        setSafeTerms(response.data.result.safe_terms);
      } catch (error) {
        console.error('Error fetching offering details:', error);
      }
    };
    fetchOfferingDetails();
  }, [auth?.token, offeringId]);

  const handleSubmitForApproval = async () => {
    try {
      if (!offering || !safeTerms) {
        console.error('Offering or SAFE terms are not available.');
        return;
      }

      // Retrieve the ABI and bytecode for the SafeRegD506b contract
      const offeringABI = contracts[chainId!].SafeRegD506b.abi;
      const offeringBytecode = contracts[chainId!].SafeRegD506b.bytecode;

      // Extract the constructor ABI
      const constructorABI = offeringABI.find(
        (item: any) => item.type === 'constructor'
      ) as any | undefined;

      if (!constructorABI) {
        throw new Error('Constructor ABI not found');
      }
      // Map offering type to enum value (assuming 'Primary' is 0 and 'Secondary' is 1)
      const offeringTypeEnum = offering.offering_type === 'Primary' ? 0 : 1;

      // Prepare constructor arguments
      const constructorArgs = [
        offeringTypeEnum, // uint8 OfferingType _offeringType
        address as `0x${string}`, // address _issuerOrSeller
        offering.offering_name, // string _name
        offering.offering_description, // string _description
        BigInt(
          ethers.utils.parseUnits(offering.soft_cap.toString(), 18).toString()
        ), // uint256 _targetAmount
        BigInt(
          ethers.utils
            .parseUnits(safeTerms.minimum_investment.toString(), 18)
            .toString()
        ), // uint256 _minInvestment
        safeTerms.currency as `0x${string}`, // address _currency
        'https://api.capsign.com/v1/offerings/' + offeringId, // string _uri (provide actual URI if available)

        // Types.SAFE memory safeTerms (as an object matching the struct)
        {
          currency: safeTerms.currency as `0x${string}`,
          discount: BigInt(parseUnits(safeTerms.discount, 2) || '0'),
          valuationCap: BigInt(parseUnits(safeTerms.valuation_cap, 2) || '0'),
          minimumInvestment: BigInt(
            ethers.utils
              .parseUnits(safeTerms.minimum_investment.toString(), 18)
              .toString()
          ),
          investmentDeadline: BigInt(
            Math.floor(new Date(safeTerms.investment_deadline).getTime() / 1000)
          ),
          uri: safeTerms.uri || '',
        },
      ];

      // Encode constructor arguments
      const encodedConstructorArgs = encodeAbiParameters(
        constructorABI.inputs,
        constructorArgs
      );

      // Compute the full bytecode
      const bytecode = concat([
        offeringBytecode as `0x${string}`,
        encodedConstructorArgs as `0x${string}`,
      ]);

      // Declare 'salt' before using it
      const salt = keccak256(
        new TextEncoder().encode(Date.now().toString())
      ) as `0x${string}`;

      const codeHash = keccak256(bytecode);
      const computedAddress = `0x${keccak256(
        concat(['0xff', baseSepoliaCreate2Deployer, salt, codeHash])
      ).slice(-40)}`;

      // Create call data
      const saltHex = ethers.utils.hexZeroPad(salt, 32);
      const callData = concat([saltHex as `0x${string}`, bytecode]);

      // Deploy the contract
      const hash = await walletClient?.sendTransaction({
        to: baseSepoliaCreate2Deployer,
        data: callData as `0x${string}`,
      });

      const result = await publicClient?.waitForTransactionReceipt({
        hash: hash!,
      });

      // Submit offering for approval
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/submit`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });
      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error('Error submitting offering for approval:', error);
    }
  };

  if (!offering) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="my-5 p-5">
      <Helmet>
        <title>Review Offering | {offering.offering_name}</title>
      </Helmet>
      <h2 className="mb-4">Review Offering</h2>

      {/* Offering Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Title>Offering Name</Card.Title>
              <Card.Text>{offering.offering_name}</Card.Text>
            </Col>
            <Col md={6}>
              <Card.Title>Offering Type</Card.Title>
              <Card.Text>{offering.offering_type}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Description</Card.Title>
              <Card.Text>{offering.offering_description}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Use of Funds</Card.Title>
              <Card.Text>{offering.use_of_funds}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Financial Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={4}>
              <Card.Title>Exemption</Card.Title>
              <Card.Text>{offering.federal_exemption}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Soft Cap</Card.Title>
              <Card.Text>${offering.soft_cap}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Hard Cap</Card.Title>
              <Card.Text>${offering.hard_cap}</Card.Text>
            </Col>
          </Row>
          {offering.offering_type !== 'SAFE' && (
            <Row className="mt-3">
              <Col md={4}>
                <Card.Title>Offering Price</Card.Title>
                <Card.Text>${offering.offering_price} per share</Card.Text>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {/* SAFE Terms Card */}
      {offering.offering_type === 'SAFE' && safeTerms && (
        <Card className="mb-4 shadow-sm">
          <Card.Body>
            <Card.Title>SAFE Terms</Card.Title>
            <Row className="mt-3">
              <Col md={6}>
                <Card.Subtitle className="mb-2">Discount</Card.Subtitle>
                <Card.Text>{safeTerms.discount}%</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Subtitle className="mb-2">Valuation Cap</Card.Subtitle>
                <Card.Text>${safeTerms.valuation_cap}</Card.Text>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Card.Subtitle className="mb-2">
                  Most Favored Nation
                </Card.Subtitle>
                <Card.Text>
                  {safeTerms.most_favored_nation ? 'Yes' : 'No'}
                </Card.Text>
              </Col>
              <Col md={6}>
                <Card.Subtitle className="mb-2">Investment Token</Card.Subtitle>
                <Card.Text>{safeTerms.currency || 'N/A'}</Card.Text>
              </Col>
            </Row>
            {/* Include other SAFE terms as needed */}
          </Card.Body>
        </Card>
      )}

      {/* Documents Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Card.Title>Documents</Card.Title>
          {documents.length > 0 ? (
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc) => (
                  <tr key={doc.document_id}>
                    <td>{doc.document_type}</td>
                    <td>
                      <a
                        href={doc.document_uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No documents uploaded.</p>
          )}
        </Card.Body>
      </Card>

      {/* Submit Button */}
      <div className="d-flex justify-content-end">
        <Button variant="primary" onClick={handleSubmitForApproval}>
          Submit for Approval
        </Button>
      </div>
    </Container>
  );
};

export default ReviewOffering;

// ModuleDataForm.tsx
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Form, Button } from 'react-bootstrap';
import { ModuleData, VestingSchedule } from '../../../types/moduleData';

// Common vesting schedules
const VESTING_SCHEDULES = [
  {
    label: 'Select a schedule...',
    value: '',
  },
  {
    label: '1-year cliff with 1/48 monthly vesting',
    value: '4year-1cliff',
    schedule: {
      cliffDuration: BigInt(365 * 24 * 60 * 60), // 1 year in seconds
      vestingDuration: BigInt(4 * 365 * 24 * 60 * 60), // 4 years in seconds
      vestingInterval: BigInt(30 * 24 * 60 * 60), // 30 days in seconds
    },
  },
  {
    label: 'No cliff, 4-year monthly vesting',
    value: '4year-no-cliff',
    schedule: {
      cliffDuration: BigInt(0),
      vestingDuration: BigInt(4 * 365 * 24 * 60 * 60),
      vestingInterval: BigInt(30 * 24 * 60 * 60),
    },
  },
];

interface ModuleDataFormProps {
  onChange: (moduleData: ModuleData) => void;
  initialValues: ModuleData | null;
}

const ModuleDataForm = ({ onChange, initialValues }: ModuleDataFormProps) => {
  const [moduleData, setModuleData] = useState<ModuleData>(
    initialValues || {
      vestingSchedule: {
        totalAmount: BigInt(0),
        startDate: BigInt(0),
        cliffDuration: BigInt(0),
        vestingDuration: BigInt(0),
        vestingInterval: BigInt(0),
      },
      eventIds: [],
      vestedAmounts: [],
    }
  );
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [vestedAmounts, setVestedAmounts] = useState<string[]>([]);
  const [currentEventId, setCurrentEventId] = useState('');
  const [currentVestedAmount, setCurrentVestedAmount] = useState('');

  // Helper function to convert BigInt timestamp to YYYY-MM-DD format
  const formatDate = (timestamp: bigint): string => {
    if (timestamp === BigInt(0)) return '';
    const date = new Date(Number(timestamp) * 1000);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Helper function to convert YYYY-MM-DD to BigInt timestamp
  const parseDate = (dateString: string): bigint => {
    if (!dateString) return BigInt(0);
    const date = new Date(dateString);
    return BigInt(Math.floor(date.getTime() / 1000));
  };

  // Local state for date string to manage the date input
  const [startDate, setStartDate] = useState<string>(
    initialValues ? formatDate(initialValues.vestingSchedule.startDate) : ''
  );

  useEffect(() => {
    // Always call onChange with current data
    const schedule: VestingSchedule = {
      totalAmount:
        Number(moduleData?.vestingSchedule.totalAmount) > 0
          ? BigInt(Number(moduleData?.vestingSchedule.totalAmount))
          : BigInt(initialValues?.vestingSchedule?.totalAmount || 0),
      startDate: parseDate(startDate),
      cliffDuration: moduleData?.vestingSchedule.cliffDuration,
      vestingDuration: moduleData?.vestingSchedule.vestingDuration,
      vestingInterval: moduleData?.vestingSchedule.vestingInterval,
    };

    // Convert event IDs to bytes32 (hash the string)
    const eventIdsBytes32 = eventIds.map((id) =>
      ethers.utils.keccak256(ethers.utils.toUtf8Bytes(id))
    );

    // Convert vested amounts to BigInt
    const vestedAmountsBigInt = vestedAmounts.map((amount) => BigInt(amount));

    const moduleDataUpdate: ModuleData = {
      vestingSchedule: schedule,
      eventIds: eventIdsBytes32,
      vestedAmounts: vestedAmountsBigInt,
    };

    console.log('moduleDataUpdate', moduleDataUpdate);
    onChange(moduleDataUpdate);
  }, [moduleData, eventIds, vestedAmounts]);

  const handleAddEventCondition = () => {
    if (currentEventId && currentVestedAmount) {
      setEventIds([...eventIds, currentEventId]);
      setVestedAmounts([...vestedAmounts, currentVestedAmount]);
      setCurrentEventId('');
      setCurrentVestedAmount('');
    }
  };

  const handleScheduleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSchedule = VESTING_SCHEDULES.find(
      (s) => s.value === e.target.value
    )?.schedule;
    if (selectedSchedule) {
      const newModuleData: ModuleData = {
        vestingSchedule: {
          totalAmount: BigInt(0),
          startDate: BigInt(Math.floor(new Date().getTime() / 1000)),
          cliffDuration: selectedSchedule.cliffDuration,
          vestingDuration: selectedSchedule.vestingDuration,
          vestingInterval: selectedSchedule.vestingInterval,
        },
        eventIds: [],
        vestedAmounts: [],
      };
      setModuleData(newModuleData);
    }
  };

  const handleVestingScheduleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setModuleData((prev) => ({
      ...prev,
      vestingSchedule: {
        ...prev.vestingSchedule,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <h4>Vesting Schedule</h4>
      <Form.Group className="mb-3">
        <Form.Label>Common Schedules</Form.Label>
        <Form.Select onChange={handleScheduleSelect}>
          {VESTING_SCHEDULES.map((schedule) => (
            <option key={schedule.value} value={schedule.value}>
              {schedule.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Total Amount</Form.Label>
        <Form.Control
          type="number"
          name="totalAmount"
          value={moduleData?.vestingSchedule.totalAmount.toString()}
          onChange={handleVestingScheduleChange}
          placeholder="Enter total amount"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Start Date</Form.Label>
        <Form.Control
          type="date"
          name="startDate"
          value={formatDate(moduleData?.vestingSchedule.startDate)}
          onChange={handleVestingScheduleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Cliff Duration (days)</Form.Label>
        <Form.Control
          type="number"
          name="cliffDuration"
          value={moduleData?.vestingSchedule.cliffDuration.toString()}
          onChange={handleVestingScheduleChange}
          placeholder="Enter cliff duration in days"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Vesting Duration (days)</Form.Label>
        <Form.Control
          type="number"
          name="vestingDuration"
          value={moduleData?.vestingSchedule.vestingDuration.toString()}
          onChange={handleVestingScheduleChange}
          placeholder="Enter vesting duration in days"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Vesting Interval (days)</Form.Label>
        <Form.Control
          type="number"
          name="vestingInterval"
          value={moduleData?.vestingSchedule.vestingInterval.toString()}
          onChange={handleVestingScheduleChange}
          placeholder="Enter vesting interval in days (optional)"
        />
      </Form.Group>

      <h4>Event-based Vesting Conditions</h4>
      <Form.Group className="mb-3">
        <Form.Label>Event ID</Form.Label>
        <Form.Control
          type="text"
          name="eventId"
          value={currentEventId}
          onChange={(e) => setCurrentEventId(e.target.value)}
          placeholder="Enter event ID"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Vested Amount</Form.Label>
        <Form.Control
          type="number"
          name="vestedAmounts"
          value={currentVestedAmount}
          onChange={(e) => setCurrentVestedAmount(e.target.value)}
          placeholder="Enter vested amount for event"
        />
      </Form.Group>

      <Button variant="secondary" onClick={handleAddEventCondition}>
        Add Event Condition
      </Button>

      {eventIds.length > 0 && (
        <div className="mt-3">
          <h5>Event Conditions</h5>
          <ul>
            {eventIds.map((id, index) => (
              <li key={index}>
                Event ID: {id}, Vested Amount: {vestedAmounts[index]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ModuleDataForm;

// EditOfferingPaymentTerms.tsx
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useReadContract,
  useWaitForTransactionReceipt,
} from 'wagmi';
import { contracts } from '../../../constants';
import { useError } from '../../../contexts/ErrorContext';
import SelectBankAccount from '../../../components/SelectBankAccount';
import {
  concat,
  encodeAbiParameters,
  keccak256,
  parseUnits,
  zeroAddress,
} from 'viem';
import { hexlify, hexZeroPad } from 'ethers/lib/utils';
import RegD506c from '@capsign/contracts/artifacts/contracts/offerings/RegD506(c).sol/RegD506c.json';

const create2DeployerAddress = '0x914d7Fec6aaC8cd542e72Bca78B30650d45643d7';

const EditOfferingPaymentTerms: React.FC = () => {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const { data: walletClient } = useWalletClient();
  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const { data: receipt, isLoading: isReceiptLoading } =
    useWaitForTransactionReceipt({
      hash: txHash,
    });
  const publicClient = usePublicClient();
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { setOffering, offering, fetchOffering } = useOffering();
  const navigate = useNavigate();
  const { setError } = useError();
  const [paymentMethod, setPaymentMethod] = useState<'crypto' | 'banking'>(
    'crypto'
  );
  const [formData, setFormData] = useState({
    paymentTokenAddress: contracts[chainId!].USD.address,
    investmentDeadline: '',
    minInvestment: 0,
    contractAddress: '',
  });
  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  useEffect(() => {
    if (offeringId) {
      fetchOffering(offeringId);
    }
  }, [offeringId]);

  const contract = contracts[chainId!].ERC7752;

  const { data: name } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'name',
  });

  const { data: symbol } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'symbol',
  });

  const { data: decimals } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'decimals',
  });

  useEffect(() => {
    const fetchPaymentTerms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/payment_terms`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        // Convert investmentDeadline to yyyy-MM-dd format
        const investmentDeadline = response.data.result.investment_deadline
          ? new Date(response.data.result.investment_deadline)
              .toISOString()
              .split('T')[0]
          : '';

        setPaymentMethod(response.data.result.payment_method || 'crypto');

        setFormData({
          ...formData,
          paymentTokenAddress: response.data.result.payment_token_address,
          investmentDeadline,
          minInvestment: response.data.result.minimum_investment,
          contractAddress: response.data.result.contract_address,
        });
      } catch (error) {
        console.error('Error fetching payment terms:', error);
      }
    };

    fetchPaymentTerms();
  }, [offeringId, auth?.token]);

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(event.target.value as 'crypto' | 'banking');
  };

  const handleFormChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const computeCreate2Address = async (
    bytecode: `0x${string}`,
    args: any[]
  ) => {
    // TODO: For the Reg CF contract. This is for identity contract still!
    const constructorAbi = [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_intermediary',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_targetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_minInvestment',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_maxInvestment',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: '_paymentToken',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
    ];
    const inputs = constructorAbi[0].inputs;
    const deployData = encodeAbiParameters(inputs, args);
    const fullBytecode = concat([bytecode, deployData]);
    const salt = keccak256(
      new TextEncoder().encode(Date.now().toString())
    ) as `0x${string}`;
    const codeHash = keccak256(fullBytecode);

    const computedAddress = `0x${keccak256(
      concat(['0xff', create2DeployerAddress, salt, codeHash])
    ).slice(-40)}`;

    return {
      computedAddress,
      salt,
      fullBytecode,
    };
  };

  const createCallData = (
    salt: `0x${string}`,
    fullBytecode: `0x${string}`
  ): string => {
    const saltHex = hexZeroPad(salt, 32);
    const callData = hexlify(concat([saltHex as `0x${string}`, fullBytecode]));

    return callData;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (!walletClient) {
        throw new Error('No wallet client available');
      }

      let contractAddress = offering?.contract_address || '';

      if (!contractAddress) {
        // Deploy contract
        const params = [
          account,
          '0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199', // Intermediary address. TODO: Set correctly
          parseUnits(offering?.soft_cap?.toString() || '0', decimals || 18),
          parseUnits(formData.minInvestment.toString() || '0', decimals || 18),
          parseUnits(offering?.hard_cap?.toString() || '0', decimals || 18),
          formData.paymentTokenAddress,
        ];
        console.log('Params:', params);

        if (chainId === 31337) {
          // Handle local deployment
          // TODO: Implement local deployment logic if needed
          alert('Local deployment is not supported yet.');
          return;
        } else {
          console.log('Deploying contract');
          const { computedAddress, salt, fullBytecode } =
            await computeCreate2Address(
              RegD506c.bytecode as `0x${string}`,
              params
            );

          const callData = createCallData(salt, fullBytecode);

          const tx = await walletClient?.sendTransaction({
            to: create2DeployerAddress,
            data: callData as `0x${string}`,
            gas: BigInt(10000000),
          });

          setTxHash(tx);
          await publicClient?.waitForTransactionReceipt({ hash: tx });

          contractAddress = computedAddress;
        }

        // Save the contract address to the offering in the backend
        await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            contract_address: contractAddress,
          },
        });
      }

      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/payment_terms`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          payment_method: paymentMethod,
          payment_token_address:
            paymentMethod === 'crypto'
              ? formData.paymentTokenAddress
              : zeroAddress, // Set to zeroAddress if banking
          investment_deadline: formData.investmentDeadline,
          minimum_investment: formData.minInvestment,
        },
      });
      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error(error);
      setError('Failed to save payment terms.');
    }
  };

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-4 offset-4">
          <h2>Edit Payment Terms</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={4}>
                  Payment Method
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    label="Crypto Transfer"
                    name="paymentMethod"
                    value="crypto"
                    checked={paymentMethod === 'crypto'}
                    onChange={handlePaymentMethodChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Bank Transfer"
                    name="paymentMethod"
                    value="banking"
                    checked={paymentMethod === 'banking'}
                    onChange={handlePaymentMethodChange}
                  />
                </Col>
              </Form.Group>
            </Row>

            {paymentMethod === 'crypto' && (
              <>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridInvestmentContract">
                    <Form.Label>Contract Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="contractAddress"
                      readOnly
                      disabled
                      placeholder="This will be auto-filled upon save"
                      value={formData.contractAddress}
                      onChange={handleFormChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPaymentToken">
                    <Form.Label>Payment Token Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="paymentTokenAddress"
                      placeholder="Enter payment token address"
                      value={formData.paymentTokenAddress}
                      onChange={handleFormChange}
                      required
                    />
                  </Form.Group>
                </Row>
                {formData.paymentTokenAddress && (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridTokenDetails">
                      <Form.Label>Token Details</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name not found"
                        value={name}
                        readOnly
                        disabled
                        className="mb-3"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Symbol not found"
                        value={symbol}
                        readOnly
                        disabled
                        className="mb-3"
                      />
                      <Form.Control
                        type="number"
                        placeholder="Decimals not found"
                        value={decimals}
                        readOnly
                        disabled
                        className="mb-3"
                      />
                    </Form.Group>
                  </Row>
                )}
              </>
            )}

            {paymentMethod === 'banking' && (
              <SelectBankAccount
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            )}

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMinInvestment">
                <Form.Label>Minimum Investment</Form.Label>
                <Form.Control
                  type="number"
                  name="minInvestment"
                  placeholder="Enter minimum investment"
                  value={formData.minInvestment}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInvestmentDeadline">
                <Form.Label>Investment Deadline</Form.Label>
                <Form.Control
                  type="date"
                  name="investmentDeadline"
                  placeholder="Enter investment deadline"
                  value={
                    formData?.investmentDeadline || formData.investmentDeadline
                  }
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Save and Finish
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingPaymentTerms;

import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Nav,
  Tab,
  Table,
  Dropdown,
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useEntity } from '../../../contexts/EntityContext';
import { v4 as uuidv4 } from 'uuid';

const Drafts = () => {
  const { entity } = useEntity();
  const securityTypes =
    entity.entity_type === 'Corporation'
      ? [
          'Shares',
          'SAFE',
          'Options',
          'RSAs',
          'RSUs',
          'SARs',
          'Warrants',
          'Convertibles',
        ]
      : ['Allocations', 'Warrants', 'Convertibles'];

  const [activeKey, setActiveKey] = useState(securityTypes[0]);
  const [drafts, setDrafts] = useState([]);
  const { auth } = useAuth();

  const getCertificateDrafts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/drafts?draft_type=${activeKey}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setDrafts(response.data.drafts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCertificateDrafts();
  }, [activeKey]);

  const deleteDraft = async (draftId: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/drafts/${draftId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      getCertificateDrafts();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Drafts
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md={11}>
            <Tab.Container
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k || securityTypes[0])}
            >
              <Nav variant="pills" className="mb-3">
                {securityTypes.map((type) => (
                  <Nav.Item key={type}>
                    <Nav.Link
                      eventKey={type}
                      disabled={
                        type !== 'Shares' &&
                        type !== 'Allocations' &&
                        type !== 'SAFE'
                      }
                    >
                      {type}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {securityTypes.map((type) => (
                  <Tab.Pane eventKey={type} key={type}>
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                        />
                      </div>
                      <NavLink
                        to={`/equity/drafts/${uuidv4()}`}
                        className="btn btn-primary"
                        state={{ draftType: type }}
                      >
                        New {type.toLowerCase()} draft
                      </NavLink>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Draft Name</th>
                          <th>Status</th>
                          <th>Last updated</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drafts.map((draft: any, index: number) => (
                          <tr key={index}>
                            <td>{draft.draft_name}</td>
                            <td>{draft.draft_status}</td>
                            <td>
                              {new Date(draft.updated_at).toLocaleDateString()}
                            </td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="secondary"
                                  id="dropdown-basic"
                                >
                                  ...
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as={NavLink}
                                    to={`/equity/drafts/${draft.draft_id}/edit`}
                                  >
                                    Edit draft
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => deleteDraft(draft.draft_id)}
                                  >
                                    Delete draft
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                        {drafts.length === 0 && (
                          <tr>
                            <td colSpan={4} className="text-center py-5">
                              No drafts found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Drafts;

import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

interface MembershipClassProps {
  initialState: any;
  onChange: (data: any, isValid: boolean) => void;
}

export default function MembershipClass({
  initialState,
  onChange,
}: MembershipClassProps) {
  const getDefault = (value: any, defaultValue: any) =>
    value !== undefined ? value : defaultValue;

  const [className, setClassName] = useState(
    getDefault(initialState?.class_name, '')
  );
  const [votingRights, setVotingRights] = useState(
    getDefault(initialState?.voting_rights, false)
  );
  const [distributionPriority, setDistributionPriority] = useState(
    getDefault(initialState?.distribution_priority, 0)
  );
  const [metadata, setMetadata] = useState(
    getDefault(initialState?.metadata, '{}')
  );
  const [prefix, setPrefix] = useState(getDefault(initialState?.prefix, ''));
  const [color, setColor] = useState(
    getDefault(initialState?.color, '#1D632D')
  );

  // Touched state for each field
  const [touched, setTouched] = useState({
    className: false,
    votingRights: false,
    distributionPriority: false,
    prefix: false,
    color: false,
    metadata: false,
  });

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  useEffect(() => {
    if (initialState) {
      if (initialState.class_name !== undefined) {
        setClassName(initialState.class_name);
      }
      if (initialState.prefix !== undefined) {
        setPrefix(initialState.prefix);
      }
      if (initialState.voting_rights !== undefined) {
        setVotingRights(initialState.voting_rights);
      }
      if (initialState.distribution_priority !== undefined) {
        setDistributionPriority(initialState.distribution_priority);
      }
      if (initialState.color !== undefined) {
        setColor(initialState.color);
      }
      if (initialState.metadata !== undefined) {
        setMetadata(initialState.metadata);
      }
    }
  }, [initialState]);

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const lettersOnly = value.replace(/[^a-zA-Z]/g, ''); // Allow only alphabetical characters
    setPrefix(lettersOnly);
  };

  // Validation
  const [isValid, setIsValid] = useState(false);

  // Check if metadata is valid JSON
  const isMetadataValid = (() => {
    try {
      JSON.parse(metadata);
      return true;
    } catch {
      return false;
    }
  })();

  // Notify parent component of changes
  useEffect(() => {
    const requiredFieldsFilled =
      className &&
      color &&
      prefix &&
      distributionPriority >= 0 &&
      isMetadataValid;

    setIsValid(requiredFieldsFilled);

    onChange(
      {
        class_id: initialState.class_id,
        class_name: className,
        voting_rights: votingRights,
        distribution_priority: distributionPriority,
        prefix,
        color,
        metadata: isMetadataValid ? JSON.parse(metadata) : null,
      },
      requiredFieldsFilled
    );
  }, [
    className,
    votingRights,
    distributionPriority,
    prefix,
    color,
    metadata,
    isMetadataValid,
  ]);

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formClassName" className="my-3">
                <Form.Label>Class Name</Form.Label>
                <Form.Control
                  type="text"
                  value={className}
                  isInvalid={touched.className && !className}
                  onBlur={() => handleBlur('className')}
                  onChange={(e) => setClassName(e.target.value)}
                  placeholder="Membership Class A"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a class name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="formVotingRights" className="my-3">
                <Form.Label>Voting Rights</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  checked={votingRights}
                  onChange={(e) => setVotingRights(e.target.checked)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="formColor" className="my-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  type="color"
                  value={color}
                  isInvalid={touched.color && !color}
                  title="Choose your color"
                  onBlur={() => handleBlur('color')}
                  onChange={(e) => setColor(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a color.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="formPrefix" className="my-3">
                <Form.Label>Class Prefix</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={prefix}
                    onChange={handlePrefixChange}
                    required
                    isInvalid={touched.prefix && !prefix}
                    onBlur={() => handleBlur('prefix')}
                  />
                  <InputGroupText>-</InputGroupText>
                  <Form.Control.Feedback type="invalid">
                    Please enter a prefix.
                  </Form.Control.Feedback>
                </InputGroup>
                <small className="text-secondary">E.g., "MCA"</small>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formDistributionPriority" className="my-3">
                <Form.Label>Distribution Priority</Form.Label>
                <Form.Control
                  type="number"
                  value={distributionPriority === 0 ? '' : distributionPriority}
                  onChange={(e) =>
                    setDistributionPriority(Number(e.target.value))
                  }
                  onBlur={() => handleBlur('distributionPriority')}
                  required
                  isInvalid={
                    touched.distributionPriority && distributionPriority < 0
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a non-negative number.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group controlId="formMetadata" className="my-3">
                <Form.Label>Metadata (JSON)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={metadata ?? ''}
                  onChange={(e) => setMetadata(e.target.value)}
                  isInvalid={touched.metadata && !isMetadataValid}
                  onBlur={() => handleBlur('metadata')}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid JSON.
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Enter additional data in JSON format.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

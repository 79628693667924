import React from 'react';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';

interface InterestFormProps {
  formData: InterestFormData;
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
}

interface InterestFormData {
  // Contributor Information
  name: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  accountAddress: string;

  // Interest Details
  allocationAmount: number;
  allocationDate: string;
  ownershipPercentage: number;
  allocationType: 'Cash' | 'Property' | 'Services';
  nonCashDescription: string;
  paymentMethod: string;
  paymentSchedule: string;

  // Membership Agreement
  effectiveDate: string;
  membershipClass: string;

  // Acknowledgment
  confirmInformation: boolean;
  signature: string;

  // Supporting Documents
  file: File | null;
}

const InterestForm: React.FC<InterestFormProps> = ({
  formData,
  handleChange,
  handleSubmit,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      {/* Recipient Information */}
      <h4>Recipient Information</h4>

      {/* Full Name */}
      <Form.Group controlId="name" className="mb-3">
        <Form.Label>
          Full Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Email */}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>
          Email Address<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Account Address */}
      <Form.Group controlId="accountAddress" className="mb-3">
        <Form.Label>
          Account Address<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="accountAddress"
          value={formData.accountAddress}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Phone Number */}
      <Form.Group controlId="phoneNumber" className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Mailing Address */}
      <Form.Group controlId="addressLine1" className="mb-3">
        <Form.Label>Address Line 1</Form.Label>
        <Form.Control
          type="text"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="addressLine2" className="mb-3">
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          type="text"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleChange}
        />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group controlId="city" className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="state" className="mb-3">
            <Form.Label>State/Province</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="zipCode" className="mb-3">
            <Form.Label>Zip/Postal Code</Form.Label>
            <Form.Control
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="country" className="mb-3">
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="text"
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Interest Details */}
      <h4 className="mt-4">Interest Details</h4>

      {/* Interest Amount */}
      <Form.Group controlId="allocationAmount" className="mb-3">
        <Form.Label>
          Interest Amount ($)<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="allocationAmount"
          value={formData.allocationAmount}
          onChange={handleChange}
          required
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Interest Date */}
      <Form.Group controlId="allocationDate" className="mb-3">
        <Form.Label>
          Interest Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="allocationDate"
          value={formData.allocationDate}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Membership Class */}
      <Form.Group controlId="membershipClass" className="mb-3">
        <Form.Label>
          Membership Class<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="membershipClass"
          value={formData.membershipClass}
          onChange={handleChange}
          required
        >
          <option value="">Select Membership Class</option>
          <option value="Manager">Manager</option>
          <option value="Investor">Investor</option>
        </Form.Select>
      </Form.Group>

      {/* Ownership Percentage */}
      <Form.Group controlId="ownershipPercentage" className="mb-3">
        <Form.Label>
          Ownership Percentage (%)<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="ownershipPercentage"
          value={formData.ownershipPercentage}
          onChange={handleChange}
          required
          min={0}
          max={100}
          step={0.01}
        />
      </Form.Group>

      {/* Interest Type */}
      <Form.Group controlId="allocationType" className="mb-3">
        <Form.Label>
          Type of Interest<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="allocationType"
          value={formData.allocationType}
          onChange={handleChange}
          required
        >
          <option value="">Select Interest Type</option>
          <option value="Cash">Cash</option>
          <option value="Property">Property</option>
          <option value="Services">Services</option>
        </Form.Select>
      </Form.Group>

      {/* Description of Non-Cash Contributions */}
      {formData.allocationType !== 'Cash' && (
        <Form.Group controlId="nonCashDescription" className="mb-3">
          <Form.Label>
            Description of Non-Cash Interest
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="nonCashDescription"
            value={formData.nonCashDescription}
            onChange={handleChange}
            required
            rows={3}
          />
        </Form.Group>
      )}

      {/* Payment Method */}
      <Form.Group controlId="paymentMethod" className="mb-3">
        <Form.Label>
          Payment Method<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="paymentMethod"
          value={formData.paymentMethod}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Payment Schedule */}
      <Form.Group controlId="paymentSchedule" className="mb-3">
        <Form.Label>Payment Schedule (if not lump sum)</Form.Label>
        <Form.Control
          as="textarea"
          name="paymentSchedule"
          value={formData.paymentSchedule}
          onChange={handleChange}
          rows={2}
        />
      </Form.Group>

      {/* Subscription Agreement */}
      <h4 className="mt-4">Subscription Agreement</h4>

      {/* Effective Date */}
      <Form.Group controlId="effectiveDate" className="mb-3">
        <Form.Label>
          Effective Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="effectiveDate"
          value={formData.effectiveDate}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Supporting Documents */}
      <Form.Group controlId="file" className="mb-4">
        <Form.Label>Subscription Agreement</Form.Label>
        <Form.Control
          type="file"
          name="file"
          onChange={handleChange}
          accept=".pdf"
        />
        <Form.Text className="text-muted">
          Accepted formats: PDF, DOC, DOCX, TXT, JPG, PNG
        </Form.Text>
      </Form.Group>

      {/* Acknowledgment */}
      <h4 className="mt-4">Acknowledgment</h4>

      {/* Confirm Information */}
      <Form.Group controlId="confirmInformation" className="mb-3">
        <Form.Check
          type="checkbox"
          name="confirmInformation"
          label="I confirm that all information provided is accurate and complete."
          checked={formData.confirmInformation}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Signature */}
      <Form.Group controlId="signature" className="mb-3">
        <Form.Label>
          Signature<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="signature"
          value={formData.signature}
          onChange={handleChange}
          required
        />
        <Form.Text className="text-muted">
          Please type your full name as a signature.
        </Form.Text>
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Submit'}
      </Button>
    </Form>
  );
};

export default InterestForm;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { Card } from 'react-bootstrap';
import zxcvbn from 'zxcvbn';
import LandingNav from '../../components/LandingNav';
import Footer from '../../components/Footer';

export default function Register(props: any) {
  const { auth, setAuth } = useAuth();
  const [registered, setRegistered] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve 'from' state
  const from = location.state?.from?.pathname || '/portfolio';

  // Helper function to parse query string parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    (async () => {
      // Check if user has already registered
      if (auth?.token) {
        try {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/users/me`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
          setRegistered(true);
          setEmail(response.data?.result?.email);
          setEmailVerified(response.data?.result?.email_verified);
        } catch (error: any) {}
      }
    })();
  }, []);

  const register = async () => {
    if (password !== confirmPassword) {
      return alert('Passwords do not match.');
    }

    if (!termsChecked || !privacyChecked) {
      return alert('Please agree to the Terms of Service and Privacy Policy.');
    }

    try {
      let response = await axios(`${process.env.REACT_APP_API_URL}/v1/users`, {
        method: 'POST',
        data: {
          email,
          password,
          token,
        },
      });
      if (response.status === 201) {
        response = await axios(
          `${process.env.REACT_APP_API_URL}/v1/users/login`,
          {
            method: 'POST',
            data: {
              email,
              password,
            },
          }
        );
        setAuth(response.data);
        setRegistered(true);
        if (token)
          return navigate('/onboarding/account/type', { state: { from } });
        else navigate('/confirm-email', { state: { from } });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'Error creating account.';
      alert(errorMessage);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    const evaluation = zxcvbn(newPassword);
    setPasswordStrength(evaluation.score);
  };

  const getStrengthClass = () => {
    switch (passwordStrength) {
      case 0:
        return 'strength-weak';
      case 1:
        return 'strength-fair';
      case 2:
        return 'strength-good';
      case 3:
        return 'strength-strong';
      case 4:
        return 'strength-very-strong';
      default:
        return 'strength-weak';
    }
  };

  return (
    <>
      <LandingNav />
      <div className="min-vh-100 bg-primary">
        <div className="h-100-sm-up d-flex flex-column align-items-center justify-content-center mx-2">
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-center align-items-center">
              <Card className="w-100 mt-4">
                <Card.Body>
                  <div className="row mt-3">
                    <div className="col-8 offset-2 text-center">
                      {/* <img
                    src={logo}
                    alt="CapSign"
                    width="200"
                    className="img-fluid"
                  /> */}
                      <h3 className="my-4 text-primary">
                        Account Registration
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-2 col-8">
                      <hr />
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-lg-4 offset-lg-4">
                      {!registered ? (
                        <form>
                          <div className="mb-3">
                            <label className="form-label">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(event) => setEmail(event.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={handlePasswordChange}
                            />
                            {password && (
                              <>
                                <div className="password-strength-bar">
                                  <div
                                    className={`password-strength-meter ${getStrengthClass()}`}
                                  ></div>
                                </div>
                                <small className="text-muted">
                                  Password strength:{' '}
                                  {
                                    [
                                      'Weak',
                                      'Fair',
                                      'Good',
                                      'Strong',
                                      'Very Strong',
                                    ][passwordStrength]
                                  }
                                </small>
                              </>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(event) =>
                                setConfirmPassword(event.target.value)
                              }
                            />
                          </div>
                          <div className="form-check mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="termsCheck"
                              onChange={() => setTermsChecked(!termsChecked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="termsCheck"
                            >
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to="https://app.termly.io/document/terms-of-service/45871e05-0337-4b1e-80f0-bae60d7df509"
                              >
                                Terms of Service
                              </Link>
                            </label>
                          </div>
                          <div className="form-check mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="privacyCheck"
                              onChange={() =>
                                setPrivacyChecked(!privacyChecked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="privacyCheck"
                            >
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to="https://app.termly.io/document/privacy-policy/3fbdf65a-0739-459f-b652-dd29d3a4428b"
                              >
                                Privacy Policy
                              </Link>
                            </label>
                          </div>
                          <div className="d-grid gap-2 mb-2">
                            <button
                              type="button"
                              onClick={register}
                              className="btn btn-primary"
                            >
                              Next
                            </button>
                          </div>
                          <small>
                            Already have an account?{' '}
                            <Link to="/login">Login</Link>
                          </small>
                        </form>
                      ) : (
                        <div className="mb-3">
                          {!emailVerified && (
                            <p>
                              Please check your email to verify your address.
                            </p>
                          )}
                          <label className="form-label">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            disabled
                          />
                          <small>
                            <button
                              className="btn btn-link ps-0"
                              onClick={() => {
                                setAuth(null);
                                setRegistered(false);
                              }}
                            >
                              Change email
                            </button>
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Nav, Navbar, Container } from 'react-bootstrap';
import { utils } from 'ethers';
import numbro from 'numbro';
import config from '../../../package.json';
import { config as wagmiConfig } from '../../config';
import ExternalSites from '../ExternalSites';
import './index.css';
import logo from '../../assets/logo.png';
import { formatOptions } from '../../constants';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useAccount } from '../../contexts/AccountContext';
import { useEntity } from '../../contexts/EntityContext';
import {
  useAccount as useConnectedAccount,
  useBlock,
  useConnect,
  useDisconnect,
  usePublicClient,
  useChainId,
  useSwitchChain,
} from 'wagmi';
import { useConnections } from 'wagmi';
import {
  chainIdToNetwork,
  decimals,
  explorerBaseUrl,
  subscriptionToken,
  subscriptionTokenAbi,
  subscriptionTokenAddress,
} from '../../utils';
import { useError } from '../../contexts/ErrorContext';
import { coinbaseWallet } from 'wagmi/connectors';
import { Chain } from 'wagmi/chains';

function Balance() {
  const { address, chainId } = useConnectedAccount();
  const [balance, setBalance] = useState('');
  const { setError } = useError();
  const publicClient = usePublicClient();

  /**
   * Set block listener
   */
  useEffect(() => {
    if (address && chainId) {
      (async () => {
        try {
          // Ensure the token address is available for the current chainId
          if (!subscriptionTokenAddress[chainId!]) {
            throw new Error(`Token address not found for chainId ${chainId}`);
          }

          // Fetch the token balance for the account
          const _balance: any = await publicClient?.readContract({
            address: subscriptionTokenAddress[chainId] as `0x${string}`,
            abi: subscriptionTokenAbi[chainId],
            functionName: 'balanceOf',
            args: [address],
          });

          // Format the balance using utils from ethers.js and numbro.js
          const formattedBalance = numbro(
            utils.formatUnits(_balance, decimals[chainId!])
          ).format(formatOptions);

          // Use your existing setBalance method
          setBalance(formattedBalance);
        } catch (error) {
          setError(error);
          console.error('Error fetching token balance:', error);
        }
      })();
    }
  }, [address, chainId]);

  if (!address) return null;
  return (
    <div
      className="your-balance mt-3 shadow-sm p-3 rounded text-truncate w-100"
      style={{ maxWidth: '275px' }}
    >
      <h3>Balance</h3>
      <span className="tokens">
        {balance} {subscriptionToken[chainId!]}
      </span>
      <br />
      <small className="text-truncate w-100 text-center">{address}</small>
    </div>
  );
}

function Sidebar() {
  const { account } = useAccount();
  const { entity } = useEntity();
  const connectedAccount = useConnectedAccount();
  const mobile = false;

  return (
    <Navbar collapseOnSelect expand="lg" className="mb-0">
      <Container fluid className="d-flex flex-column h-100">
        <div className="d-flex flex-row justify-content-between align-items-center w-100 align-top">
          <Navbar.Brand href="https://capsign.com/" className="p-0">
            <img src={logo} alt="CapSign" height="30" />
            &nbsp;CapSign
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Balance />
        <Navbar.Collapse className="w-100 mt-3" id="responsive-navbar-nav">
          <Nav className="flex-column w-100">
            {/* <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? 'active nav-link' : 'nav-link'
              }
              to="/home"
            >
              <i className="fa-solid fa-home"></i> Home
            </NavLink>
          </li> */}
            {account?.account_type === 'Business Entity' && (
              <>
                <NavLink className="nav-link my-1" to="/equity">
                  <i className="fa-solid fa-scale-balanced"></i> Equity
                </NavLink>
                <NavLink className="nav-link my-1" to="/transfers">
                  <i className="fa-solid fa-right-left"></i> Transfers
                </NavLink>
              </>
            )}
            <NavLink className="nav-link my-1" to="/offerings">
              <i className="fa-solid fa-file-signature"></i> Offerings
            </NavLink>
            {(account?.account_type !== 'Business Entity' ||
              ['Investor', 'Both'].includes(entity?.activity_type!)) && (
              <NavLink
                to="/portfolio"
                className={({ isActive }) =>
                  isActive ? 'nav-link my-1 active' : 'nav-link my-1'
                }
              >
                <i className="fa-solid fa-chart-pie"></i> Portfolio
              </NavLink>
            )}
            <NavLink
              to="/markets"
              className={({ isActive }) =>
                isActive ? 'nav-link my-1 active' : 'nav-link my-1'
              }
            >
              <i className="fa-solid fa-chart-simple"></i> Markets
            </NavLink>
            {/* <NavLink
              to="/ledgers"
              className={({ isActive }) =>
                isActive ? 'nav-link my-1 active' : 'nav-link my-1'
              }
            >
              <i className="fa-solid fa-book-open"></i> Ledgers
            </NavLink> */}
            <NavLink className="nav-link my-1" to="/profile">
              <i className="fa-solid fa-user"></i> Profile
            </NavLink>
            {/* <NavLink
              className={({ isActive }) =>
                isActive ? 'nav-link my-1 active' : 'nav-link my-1'
              }
              to="/archive"
            >
              <i className="fa-solid fa-box-archive"></i> Archive
            </NavLink> */}
            <NavLink className="nav-link my-1" to="/settings">
              <i className="fa-solid fa-gear"></i> Settings
            </NavLink>
            <div className="mt-auto w-100">
              <form className="mb-3" style={{ display: 'block' }}>
                {connectedAccount.status === 'connected' && !mobile && (
                  <ProviderInfo />
                )}
              </form>
              <div className="container-fluid text-center">
                <ExternalSites />
                <small className="d-none d-xl-block">v{config.version}</small>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function ProviderInfo() {
  const block = useBlock({ watch: true });
  const account = useConnectedAccount();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const connector = useConnections();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const chainId = useChainId();
  const { entity } = useEntity();
  const { switchChain } = useSwitchChain();
  const [availableChains, setAvailableChains] = useState<Chain[]>([]);
  const { setError } = useError();

  useEffect(() => {
    // Fetch allowed networks from config.ts
    let chains;
    if (process.env.NODE_ENV === 'development') {
      chains = [...wagmiConfig.chains];
    } else {
      chains = [...wagmiConfig.chains.filter((chain) => chain.id === 8453)];
      if (chainId !== 8453) {
        switchChain({
          chainId: 8453,
          connector: wagmiConfig.connectors[0],
        });
      }
    }
    setAvailableChains(chains);
  }, []);

  const logout = () => {
    localStorage.removeItem(`capsign__auth_${process.env.NODE_ENV}`);
    localStorage.removeItem(`capsign__onboarding`);
    setAuth(null);
    navigate('/login');
  };

  const onClick = async () => {
    connect({
      chainId: 84532,
      connector: coinbaseWallet(),
    });
  };

  const handleNetworkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newChainId = Number(event.target.value);
    try {
      switchChain?.({
        chainId: newChainId,
        connector: wagmiConfig.connectors[0],
      });
    } catch (error) {
      setError(error);
      console.error('Failed to switch network:', error);
    }
  };

  return (
    <div className="card text-primary mt-2 d-xl-flex">
      <div className="card-body">
        {entity ? (
          <>
            <div className="chain-info">Entity: {entity.short_name}</div>
          </>
        ) : null}
        {block && (
          <>
            <div className="chain-info">
              Network:&nbsp;
              <select
                value={chainId}
                onChange={handleNetworkChange}
                className="form-select"
              >
                {availableChains.map((chain: any) => (
                  <option key={chain.id} value={chain.id}>
                    {chain.name}
                  </option>
                ))}
              </select>
            </div>
            <div>Connection: {connector[0]?.connector?.name}</div>
          </>
        )}
        {account.status === 'connected' ? (
          <>
            <div className="connected">
              Status: Connected{' '}
              <i className="inline-block far fa-dot-circle text-success" />
            </div>
            Block:{' '}
            {numbro(block.data?.number.toString()).format({
              thousandSeparated: true,
            })}
            <div>
              <Link
                to={explorerBaseUrl[chainId]}
                className="text-primary text-decoration-none"
                target="_blank"
              >
                Explorer <i className="fa fa-external-link"></i>
              </Link>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="secondary"
                className="w-100 border"
                onClick={() => disconnect()}
              >
                Disconnect
              </Button>
            </div>
            {process.env.REACT_APP_REQUIRE_AUTH === 'true' && (
              <Button
                variant="light"
                className="w-100 border mt-2"
                onClick={logout}
              >
                Logout
              </Button>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-light text-success border-success"
              type="button"
              onClick={onClick}
            >
              <i className="fas fa-wallet mr-2" /> Connect to a wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;

import { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import useLocalStorageState from 'use-local-storage-state';
import Sidebar from '../../components/Sidebar';
import Profile from '../../pages/Profile';
import General from '../../pages/Settings/General';
import Banking from '../../pages/Settings/Banking';
import Billing from '../../pages/Settings/Billing';
import Security from '../../pages/Settings/Security';
import IdentityDocuments from '../../pages/Archive/IdentityDocuments';
import CorporateDocuments from '../../pages/Archive/CorporateDocuments';
import { version } from '../../constants';
import ExternalSites from '../../components/ExternalSites';
import EventContext from '../../contexts/TransactionContext';
import Equity from '../../pages/Equity';
import CreateClasses from '../../pages/Equity/CreateClasses';
import Transfers from '../../pages/Transfers';
import Markets from '../../pages/Markets';
import Portfolio from '../../pages/Portfolio';
import Settings from '../../pages/Settings';
import Secondary from '../../pages/Markets/Secondary';
import SPV from '../../pages/Markets/SPV';
import Primary from '../../pages/Markets/Primary';
import DataRoomAccounts from '../../pages/DataRoom/Accounts';
import CompanyList from '../../pages/Markets/CompanyList';
import BuyForm from '../../pages/Markets/Secondary/BuyForm';
import SellForm from '../../pages/Markets/Secondary/SellForm';
import Notifications from '../../pages/Settings/Notifications';
import { useAccount } from '../../contexts/AccountContext';
import ViewClass from '../../pages/Equity/ViewClasses';
import './index.css';
import ErrorToast from '../../components/ErrorToast';
import MessageToast from '../../components/MessageToast';
import Identities from '../../pages/Identities';
import ViewStakeholder from '../../pages/Identities/ViewStakeholder';
import RegisterStakeholde from '../../pages/Identities/RegisterStakeholder';
import RegisterContract from '../../pages/Identities/RegisterContract';
import Apps from '../../pages/Settings/Apps';
import AppOverview from '../../pages/Settings/Apps/AppOverview';
import API from '../../pages/Settings/API';
import Compliance from '../../pages/Compliance';
import AppInfo from '../../pages/Settings/Apps/Info';
import AppReviews from '../../pages/Settings/Apps/Reviews';
import AppPricing from '../../pages/Settings/Apps/Pricing';
import EditClass from '../../pages/Equity/EditClass';
import GoogleDriveCallback from '../../pages/GoogleDriveCallback';
import Offerings from '../../pages/Offerings';
import ViewOffering from '../../pages/Offerings/ViewOffering';
import CreateOfferingDetails from '../../pages/Offerings/CreateOffering/OfferingDetails';
import CreateOfferingDocuments from '../../pages/Offerings/CreateOffering/OfferingDocuments';
import CreateOfferingPaymentTerms from '../../pages/Offerings/CreateOffering/PaymentTerms';
import CreateOfferingSafeTerms from '../../pages/Offerings/CreateOffering/SafeTerms';
import ReviewOffering from '../../pages/Offerings/CreateOffering/ReviewOffering';
import EditOfferingSafeTerms from '../../pages/Offerings/EditOffering/SafeTerms';
import EditOfferingPaymentTerms from '../../pages/Offerings/EditOffering/PaymentTerms';
import EditOfferingDetails from '../../pages/Offerings/EditOffering/OfferingDetails';
import EditOfferingDocuments from '../../pages/Offerings/EditOffering/OfferingDocuments';
import OfferingDetails from '../../pages/Offerings/ViewOffering/OfferingDetails';
import OfferingFinancials from '../../pages/Offerings/ViewOffering/OfferingFinancials';
import OfferingDocuments from '../../pages/Offerings/ViewOffering/OfferingDocuments';
import OfferingVideo from '../../pages/Offerings/ViewOffering/OfferingVideo';
import OfferingPress from '../../pages/Offerings/ViewOffering/OfferingPress';
import OfferingFAQ from '../../pages/Offerings/ViewOffering/OfferingQuestions';
import OfferingUpdates from '../../pages/Offerings/ViewOffering/OfferingUpdates';
import {
  Connector,
  useConnect,
  useAccount as useConnectedAccount,
} from 'wagmi';
import { coinbaseWallet } from 'wagmi/connectors';
import RegisterTransferAgent from '../../pages/Identities/RegisterTransferAgent';
import Archive from '../../pages/Archive';
import Ledgers from '../../pages/Ledgers';
import LedgerAccounts from '../../pages/Ledgers/Accounts';
import AccountTransactions from '../../pages/Ledgers/AccountTransactions';
import Drafts from '../../pages/Equity/Drafts';
import NewDraft from '../../pages/Equity/Drafts/NewDraft';
import ReviewDraft from '../../pages/Equity/Drafts/ReviewDraft';
import EditDraft from '../../pages/Equity/Drafts/EditDraft';
import { base, baseSepolia, hardhat } from 'wagmi/chains';
import ViewSafes from '../../pages/Equity/ViewSafes';

function AuthenticatedAppContent() {
  const { account } = useAccount();
  const connectedAccount = useConnectedAccount();
  const { connectors, connect } = useConnect();
  const [displayInfoAlert, setDisplayInfoAlert] = useLocalStorageState(
    'displayInfoAlert',
    { defaultValue: false }
  );
  const [transactions, setTransactions]: any = useState(
    localStorage.getItem('capsign-txs')
      ? JSON.parse(localStorage.getItem('capsign-txs')!)
      : []
  );
  const updateTransactions = (transaction: any) => {
    const newTxs = [...transactions, transaction];
    localStorage.setItem('capsign-txs', JSON.stringify(newTxs));
    setTransactions(newTxs);
  };

  const connectWallet = () => {
    const defaultChainId =
      process.env.NODE_ENV === 'development'
        ? 31337 // Hardhat
        : base.id;

    connect({
      chainId: defaultChainId,
      connector: connectors.find(
        (connector) => connector.id === 'coinbaseWalletSDK'
      ) as Connector,
    });
  };

  return (
    <div className="d-flex main-container min-vh-100">
      <div className="min-vh-100 left-nav">
        <EventContext.Provider value={{ transactions, updateTransactions }}>
          <Sidebar />
        </EventContext.Provider>
      </div>
      <div className="flex-grow-1 min-vh-100 page-container">
        {/* <Navbar /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {displayInfoAlert ? (
                <Alert
                  variant="info"
                  className="d-flex justify-content-between"
                >
                  <div>
                    <strong>
                      <i className="fas fa-exclamation-circle"></i> Notice
                    </strong>{' '}
                    This app is for demo purposes only.
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => {
                      setDisplayInfoAlert(false);
                    }}
                  ></button>
                </Alert>
              ) : null}
            </div>
          </div>

          <div className="row">
            <EventContext.Provider value={{ transactions, updateTransactions }}>
              {connectedAccount.status !== 'connected' ? (
                <div>
                  <div className="col-lg-4 col-sdm-12 offset-lg-4">
                    <div className="shadow-sm border p-5 mt-5 rounded text-center">
                      <h3>Connect a wallet to enter</h3>
                      {/* {(() => {
                        switch (error?.name) {
                          case 'UnsupportedChainIdError':
                            return (
                              <span className="text-danger">
                                {error.message}
                              </span>
                            );
                          case 'TransportError':
                            return (
                              <span className="text-danger">
                                {error.message}
                              </span>
                            );
                          case 'NoEthereumProviderError':
                            return (
                              <span className="text-danger">
                                {error.message}
                              </span>
                            );
                          case undefined:
                            return (
                              <span className="text-danger">
                                {error?.message}
                              </span>
                            );
                          default:
                            if (error?.message)
                              return (
                                <span className="text-danger">
                                  {error?.message}
                                </span>
                              );
                            return JSON.stringify(error);
                        }
                      })()} */}
                      <br />
                      <br />
                      <button
                        className="btn btn-outline-success"
                        type="button"
                        onClick={connectWallet}
                      >
                        <i className="fas fa-wallet mr-2" /> Connect wallet
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Routes>
                  {account?.account_type === 'Business Entity' && (
                    <>
                      <Route path="equity">
                        <Route index element={<Equity />} />
                        <Route path="safes">
                          <Route index element={<ViewSafes />} />
                          <Route path=":safeContract" element={<ViewSafes />} />
                        </Route>
                        <Route path="drafts">
                          <Route index element={<Drafts />} />
                          <Route path=":draftId">
                            <Route index element={<NewDraft />} />
                            <Route path="edit" element={<EditDraft />} />
                            <Route path="review" element={<ReviewDraft />} />
                          </Route>
                        </Route>
                        <Route path="create" element={<CreateClasses />} />
                        <Route path=":tokenId">
                          <Route index element={<ViewClass />} />
                          <Route path="edit" element={<EditClass />} />
                          <Route path="identities">
                            <Route index element={<Identities />} />
                            <Route
                              path="register-transfer-agent"
                              element={<RegisterTransferAgent />}
                            />
                            <Route
                              path="register-identity"
                              element={<RegisterStakeholde />}
                            />
                            <Route
                              path="register-contract"
                              element={<RegisterContract />}
                            />
                            <Route
                              path=":identityId"
                              element={<ViewStakeholder />}
                            />
                          </Route>
                          <Route path="compliance" element={<Compliance />} />
                        </Route>
                      </Route>
                      <Route path="offerings">
                        {/* Create Routes */}
                        <Route
                          path="/offerings/create"
                          element={<CreateOfferingDetails />}
                        />
                        <Route
                          path="/offerings/:offeringId/documents/create"
                          element={<CreateOfferingDocuments />}
                        />
                        <Route
                          path="/offerings/:offeringId/payment_terms/create"
                          element={<CreateOfferingPaymentTerms />}
                        />
                        <Route
                          path="/offerings/:offeringId/safe_terms/create"
                          element={<CreateOfferingSafeTerms />}
                        />
                        <Route
                          path="/offerings/:offeringId/review"
                          element={<ReviewOffering />}
                        />
                        {/* Edit Routes */}
                        <Route
                          path="/offerings/:offeringId/edit/details"
                          element={<EditOfferingDetails />}
                        />
                        <Route
                          path="/offerings/:offeringId/edit/documents"
                          element={<EditOfferingDocuments />}
                        />
                        <Route
                          path="/offerings/:offeringId/edit/safe_terms"
                          element={<EditOfferingSafeTerms />}
                        />
                        <Route
                          path="/offerings/:offeringId/edit/payment_terms"
                          element={<EditOfferingPaymentTerms />}
                        />
                      </Route>
                      <Route path="transfers" element={<Transfers />} />
                      <Route path="archive" element={<Archive />} />
                    </>
                  )}
                  <Route
                    path="/auth/google/callback"
                    element={<GoogleDriveCallback />}
                  />
                  <Route path="offerings">
                    {/* View Routes */}
                    <Route index element={<Offerings />} />
                    <Route
                      path="/offerings/:offeringId"
                      element={<ViewOffering />}
                    >
                      <Route
                        index
                        path="details"
                        element={<OfferingDetails />}
                      />
                      <Route
                        path="financials"
                        element={<OfferingFinancials />}
                      />
                      <Route path="documents" element={<OfferingDocuments />} />
                      <Route path="video" element={<OfferingVideo />} />
                      <Route path="press" element={<OfferingPress />} />
                      <Route path="faq" element={<OfferingFAQ />} />
                      <Route path="updates" element={<OfferingUpdates />} />
                    </Route>
                  </Route>
                  <Route path="markets" element={<Markets />}>
                    <Route index element={<CompanyList />} />
                    <Route path="primary">
                      <Route path=":tokenId">
                        <Route index element={<Primary />} />
                        <Route
                          path="data-room"
                          element={<DataRoomAccounts />}
                        />
                      </Route>
                    </Route>
                    <Route path="secondary">
                      <Route path=":tokenId">
                        <Route index element={<Secondary />} />
                        <Route path="buy" element={<BuyForm />} />
                        <Route path="sell" element={<SellForm />} />
                      </Route>
                    </Route>
                    <Route path="spv" element={<SPV />} />
                  </Route>
                  <Route
                    path="portfolio"
                    element={
                      <div className="col-md-12">
                        <Portfolio />
                      </div>
                    }
                  />
                  <Route path="ledgers">
                    <Route index element={<Ledgers />} />
                    <Route path=":ledger_id">
                      <Route index element={<LedgerAccounts />} />
                      <Route
                        path="accounts/:ledger_account_id/transactions"
                        element={<AccountTransactions />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/archive"
                    element={
                      <div className="offset-xl-4 col-xl-3 col-lg-6 col-md-12">
                        <IdentityDocuments />
                      </div>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-12">
                        <Profile />
                      </div>
                    }
                  />
                  <Route path="/settings" element={<Settings />}>
                    <Route index element={<General />} />
                    <Route path="apps">
                      <Route index element={<Apps />} />
                      <Route path=":id" element={<AppOverview />}>
                        <Route path="info" element={<AppInfo />} />
                        <Route path="reviews" element={<AppReviews />} />
                        <Route path="pricing" element={<AppPricing />} />
                      </Route>
                    </Route>
                    <Route path="banking" element={<Banking />} />
                    <Route path="billing" element={<Billing />} />
                    <Route path="security" element={<Security />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="api" element={<API />} />
                  </Route>
                </Routes>
              )}
            </EventContext.Provider>
          </div>
          <div className="row d-lg-none">
            <div className="col-md-12 mt-5">
              <div className="text-center mb-4">
                <ExternalSites />
                <div className="spacer spacer-1" />
                <small className="container-fluid text-muted" id="version">
                  v{version}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        {<ErrorToast />}
        {<MessageToast />}
      </div>
    </div>
  );
}

export default AuthenticatedAppContent;

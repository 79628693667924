import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { useEntity } from '../../../contexts/EntityContext';

const EquityOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { entity } = useEntity();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState<{ [key: string]: File | null }>(
    {}
  );
  const [optionalDocuments, setOptionalDocuments] = useState<File[]>([]);

  useEffect(() => {
    // Initialize required documents based on federal exemption
    let requiredDocs: string[] = [];

    const reitRequiredDocs = [
      'Private Placement Memorandum',
      'Subscription Agreement',
    ];

    switch (offering.federal_exemption) {
      case 'Reg CF':
        requiredDocs = [
          'Form C',
          'Financial Statements',
          'Subscription Agreement',
          'Pitch Deck',
          'Use of Funds Statement',
          'Risk Factors Document',
          'Capitalization Table',
        ];
        break;
      case 'Reg D 506(b)':
      case 'Reg D 506(c)':
        if (entity.entity_type === 'Trust') {
          requiredDocs = reitRequiredDocs;
        } else {
          requiredDocs = [
            'Private Placement Memorandum',
            'Subscription Agreement',
            'Financial Statements',
            'Pitch Deck',
            'Use of Funds Statement',
            'Risk Factors Document',
            'Capitalization Table',
          ];
        }
        break;
      default:
        requiredDocs = [];
        break;
    }

    const initialDocuments = requiredDocs.reduce(
      (acc, doc) => ({ ...acc, [doc]: null }),
      {}
    );
    setDocuments(initialDocuments);
  }, [offering]);

  const handleFileChange = (docType: string, file: File | null) => {
    setDocuments((prev) => ({
      ...prev,
      [docType]: file,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Upload required documents
      for (const [docType, file] of Object.entries(documents)) {
        if (file) {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('type', docType);

          await axios.post(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
        }
      }

      // Handle optional documents similarly if needed

      navigate(`/offerings/${offeringId}/payment_terms/create`);
    } catch (error) {
      console.error('Error uploading documents:', error);
      // Handle error
    }
  };

  return (
    <Container className="p-5">
      <h2>Upload Offering Documents</h2>
      <Form onSubmit={handleSubmit}>
        {Object.keys(documents).map((docType) => (
          <Form.Group
            key={docType}
            controlId={docType.replace(/ /g, '_')}
            className="mb-3"
          >
            <Form.Label>{docType}</Form.Label>
            <Form.Control
              type="file"
              onChange={(e: any) =>
                handleFileChange(docType, e.target.files?.[0] || null)
              }
              required
            />
          </Form.Group>
        ))}

        {/* Include optional documents if applicable */}

        <Button variant="primary" type="submit" className="mt-3">
          Save and Continue
        </Button>
      </Form>
    </Container>
  );
};

export default EquityOfferingDocuments;

import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';

const capsignUpdates = [
  {
    title: 'Registered with the SEC',
    content:
      'Completed filing for CapSign Securities LLC registration as a Funding Portal.',
    date: new Date('7/1/2024'),
  },
  {
    title: 'Submitted FINRA SAA Form',
    content:
      'Submitted FINRA form for Super Account Administrator credentials for CapSign Securities LLC',
    date: new Date('7/9/2024'),
  },
  {
    title: 'Equity Token Standard Draft',
    content:
      'Released draft of new Ethereum standard for equity tokens available for  public comment.',
    date: new Date('8/6/2024'),
  },
];

type Update = {
  title: string;
  content: string;
  date: Date;
};

const generationsTrustUpdates: Update[] = [
  {
    title: 'Trust Declaration Filed',
    content:
      'Filed the Generations Monterey Peninsula REIT Trust Declaration to establish the legal framework.',
    date: new Date('8/15/2024'),
  },
  {
    title: 'Banking Infrastructure Established',
    content:
      'Opened operating and capital contributions accounts for the REIT with Column Bank.',
    date: new Date('9/10/2024'),
  },
  {
    title: 'SEC Compliance Review Completed',
    content:
      'Completed a compliance review to ensure the REIT meets SEC requirements for Regulation D offerings.',
    date: new Date('10/5/2024'),
  },
  {
    title: 'First Target Property Identified',
    content:
      'Selected 1463 Oleada Road, Pebble Beach, as a primary acquisition target for the REIT portfolio.',
    date: new Date('10/15/2024'),
  },
  {
    title: 'Investor Portal Launched',
    content:
      'Deployed the Generations Trust investor portal to allow investors to review performance and manage their investments.',
    date: new Date('10/30/2024'),
  },
  {
    title: 'Marketing Campaign Initiated',
    content:
      'Launched a marketing campaign in the Monterey Peninsula area to attract local accredited investors.',
    date: new Date('11/1/2024'),
  },
  {
    title: 'Capital Raise Initiated',
    content:
      'Opened the Generations Trust capital raise for a $27M equity offering with a 50% LTV for acquisitions.',
    date: new Date('11/10/2024'),
  },
  {
    title: 'First Property Acquired',
    content:
      'Completed acquisition of 1463 Oleada Road, Pebble Beach, as the first property in the REIT portfolio.',
    date: new Date('11/18/2024'),
  },
  {
    title: 'Quarterly Investor Report',
    content:
      'Published the first quarterly report outlining performance metrics, distributions, and updates on acquisitions.',
    date: new Date('1/15/2025'),
  },
];

export default function OfferingUpdates() {
  const [updates, setUpdates] = useState(generationsTrustUpdates);
  const { auth } = useAuth();
  const { setError } = useError();

  /**
   * Fetch the current offering updates
   */
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_URL}/v1/offerings/updates`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${auth?.token}`,
        //     },
        //   }
        // );
        // setUpdates(response.data.result);
      } catch (error: any) {
        console.error('Error fetching updates:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchUpdates();
  }, [auth, setError]);

  return (
    <Container className="mt-5">
      <h1>Company Updates</h1>
      <Row>
        {updates.map((update: any, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{update.title}</Card.Title>
                <Card.Text>{update.content}</Card.Text>
                <Card.Footer className="text-muted">
                  {new Date(update.date).toLocaleDateString()}
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useChainId } from 'wagmi';
import emptyIcon from './empty.png';
import { useEntity } from '../../contexts/EntityContext';

export default function Equity() {
  const { auth } = useAuth();
  const chainId = useChainId();
  const { entity } = useEntity();
  const [shareClasses, setShareClasses] = useState<any>([]);
  const [membershipClasses, setMembershipClasses] = useState<any>([]);
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [safeContract, setSafeContract] = useState<string | null>(null);
  /**
   * Fetch share classes
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/share_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setShareClasses(result);
          for (let shareClass of result) {
            if (shareClass.token_address) setHasToken(true);
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch membership classes
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/membership_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setMembershipClasses(result);
          for (let membershipClass of result) {
            if (membershipClass.token_id) setHasToken(true);
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch SAFE contract
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/safe_contract`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;

          if (result) setHasToken(true);
          setSafeContract(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  return (
    <>
      <Container className="py-5" fluid>
        {hasToken ? (
          <>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="mb-3">
                {membershipClasses.map(
                  (membershipClass: any, index: number) => (
                    <NavLink
                      to={`/equity/${membershipClass.token_address}`}
                      className="text-decoration-none"
                      key={index}
                      state={{
                        shareClass: membershipClass,
                        entity,
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                        <div className="text-truncate">
                          <span className="text-dark">
                            {membershipClass.class_name}
                          </span>
                          <br />
                        </div>
                        {/* TODO: Link to explorer */}
                        <div className="d-none d-md-block">
                          {membershipClass.token_address}
                        </div>
                      </div>
                    </NavLink>
                  )
                )}
                {shareClasses.map((shareClass: any, index: number) => (
                  <NavLink
                    to={`/equity/${shareClass.token_address}`}
                    className="text-decoration-none"
                    key={index}
                    state={{
                      shareClass,
                      entity,
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                      <div className="text-truncate">
                        <span className="text-dark">
                          {shareClass.class_name}
                        </span>
                        <br />
                      </div>
                      {/* TODO: Link to explorer */}
                      <div className="d-none d-md-block">
                        {shareClass.token_address}
                      </div>
                    </div>
                  </NavLink>
                ))}
                {safeContract && (
                  <NavLink
                    to={`/equity/safes/${safeContract}`}
                    className="text-decoration-none"
                  >
                    <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                      <div className="text-truncate">
                        <span className="text-dark">SAFEs</span>
                        <br />
                      </div>
                      <div className="d-none d-md-block">{safeContract}</div>
                    </div>
                  </NavLink>
                )}
              </Col>
            </Row>
            <Dashboard />
          </>
        ) : (
          <Row className="h-100">
            <Col md={{ span: 8, offset: 2 }} className="mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Equity Management Dashboard</h4>
                <NavLink to="/equity/create" className="btn btn-primary">
                  New Token
                </NavLink>
              </div>
              <div className="justify-content-center align-items-center d-flex mt-5">
                <img src={emptyIcon} className="img-fluid" />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

// src/pages/Offerings/EditOffering/EditOfferingSafeTerms.tsx

import React, { useState, useEffect } from 'react';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import SAFE from '@capsign/contracts/artifacts/contracts/securities/token/SAFE.sol/SAFE.json';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import usdcImage from '../../../assets/images/usdc.png';
import ethImage from '../../../assets/images/eth.png';
import TokenInputDropdown from '../CreateOffering/TokenInputDropdown';
import { Abi, parseEventLogs, zeroAddress } from 'viem';
import { contracts } from '../../../constants';

const EditOfferingSafeTerms: React.FC = () => {
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const { offering, fetchOffering } = useOffering();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const chainId = useChainId();

  const {
    data: factoryTxHash,
    error: factoryError,
    writeContractAsync: deployTREXSuite,
  } = useWriteContract();

  const recommendedTokens = [
    {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      name: 'USD Coin',
      imageUrl: usdcImage,
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ethereum',
      imageUrl: ethImage,
    },
  ];

  const [formData, setFormData] = useState<any>({
    discount: '0',
    valuationCap: '0',
    mostFavoredNation: false,
    currency: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
    minimumInvestment: '',
    investmentDeadline: '',
    boardApprovalDate: '',
    stockholderApprovalDate: '',
    considerationText: '',
    proRataPercentage: '0',
    seniority: '1',
    uri: '',
  });

  const [contractAddress, setContractAddress] = useState<
    `0x${string}` | undefined
  >();

  useEffect(() => {
    // Fetch existing SAFE terms from the backend
    const fetchSafeTerms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_terms`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        const safeTerms = response.data.result;

        setFormData({
          discount: safeTerms.discount || '',
          valuationCap: safeTerms.valuation_cap || '',
          mostFavoredNation: safeTerms.most_favored_nation || false,
          currency: safeTerms.currency || '',
          minimumInvestment: safeTerms.minimum_investment || '',
          investmentDeadline: safeTerms.investment_deadline
            ? new Date(safeTerms.investment_deadline)
                .toISOString()
                .split('T')[0]
            : '',
          boardApprovalDate: safeTerms.board_approval_date
            ? new Date(safeTerms.board_approval_date)
                .toISOString()
                .split('T')[0]
            : '',
          stockholderApprovalDate: safeTerms.stockholder_approval_date
            ? new Date(safeTerms.stockholder_approval_date)
                .toISOString()
                .split('T')[0]
            : '',
          considerationText: safeTerms.consideration_text || '',
          proRataPercentage: safeTerms.pro_rata_percentage || 0,
          seniority: safeTerms.seniority || '',
          uri: safeTerms.uri || '',
        });
        setContractAddress(safeTerms.contract_address);
      } catch (error) {
        console.error('Error fetching SAFE terms:', error);
        setError('Failed to fetch SAFE terms.');
      }
    };

    fetchSafeTerms();
  }, [offeringId, auth?.token]);

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (walletClient && !offering?.contract_address) {
        const tokenType = 3; // TokenType for SAFE

        // Prepare the data for deployment
        const salt = new Date().toISOString(); // Unique salt for deployment

        const tokenDetails = {
          name: 'CapSign Inc. SAFE Registry',
          symbol: 'SAFE',
          decimals: BigInt(0),
          irAgents: [account!],
          tokenAgents: [account!],
          complianceModules: [], // Add any compliance modules if needed
          complianceSettings: [],
          ONCHAINID: '0x0000000000000000000000000000000000000000',
          owner: account!,
          irs: '0x0000000000000000000000000000000000000000',
        };

        const claimDetails = {
          claimTopics: [],
          issuers: [],
          issuerClaims: [],
        };

        const args = [salt, tokenDetails, claimDetails, tokenType];

        // Estimate gas
        let gas = await publicClient?.estimateContractGas({
          address: contracts[chainId!].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId!].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          account: account!,
        });

        // Deploy the SAFE contract via the factory
        const hash = await deployTREXSuite({
          address: contracts[chainId!].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId!].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          gas: gas,
        });

        let receipt = await publicClient?.waitForTransactionReceipt({
          hash: hash as `0x${string}`,
        });

        // Parse the event logs to get the deployed contract address
        const logs = parseEventLogs({
          abi: contracts[chainId!].TREXFactory.abi as Abi,
          logs: receipt?.logs!,
        });

        let event = logs.find((l) => l.eventName === 'TREXSuiteDeployed');
        console.log({ event });

        let { _token: deployedAddress, _ir } = event?.args as any;

        setTxHash(hash as `0x${string}`);
        setContractAddress(deployedAddress as `0x${string}`);

        // Now call createSafeTerms
        const safeContract = {
          address: deployedAddress as `0x${string}`,
          abi: SAFE.abi,
        };

        // Prepare the SAFE terms
        const safeTerms = {
          discount: formData.discount,
          valuationCap: formData.valuationCap,
          currency: formData.currency,
          // mostFavoredNation: formData.mostFavoredNation,
          // considerationText: formData.considerationText,
          // proRataPercentage: formData.proRataPercentage,
          // seniority: formData.seniority,
          // uri: formData.uri,
        };

        // Call the createSafeClass function
        const createSafeClassHash = await walletClient.writeContract({
          ...safeContract,
          functionName: 'createSafeClass',
          args: [safeTerms],
          account: account!,
        });

        const createSafeClassReceipt =
          await publicClient?.waitForTransactionReceipt({
            hash: createSafeClassHash,
          });

        // Extract tokenId from the IssuedSAFE event
        let tokenId: bigint | undefined;

        if (createSafeClassReceipt?.logs) {
          for (const log of createSafeClassReceipt.logs) {
            try {
              const event = parseEventLogs({
                abi: SAFE.abi as Abi,
                logs: [log],
              })[0];
              if (event.eventName === 'IssuedSAFE') {
                tokenId = (event.args as any).tokenId;
                break;
              }
            } catch (error) {
              // Not the event we are looking for
            }
          }
        }

        // Create the safe terms on the backend
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_terms`,
          {
            discount: formData.discount,
            valuation_cap: formData.valuationCap,
            most_favored_nation: formData.mostFavoredNation,
            currency: formData.currency,
            minimum_investment: formData.minimumInvestment,
            board_approval_date: formData.boardApprovalDate,
            stockholder_approval_date: formData.stockholderApprovalDate,
            consideration_text: formData.considerationText,
            pro_rata_percentage: formData.proRataPercentage,
            investment_deadline: formData.investmentDeadline,
            contract_address: deployedAddress,
            seniority: formData.seniority,
            uri: formData.uri,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        // TODO: Deploy the offering contract
      } else {
        // TODO: Call update methods on SAFE contract

        // Update the safe terms on the backend
        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_terms`,
          {
            discount: formData.discount,
            valuation_cap: formData.valuationCap,
            most_favored_nation: formData.mostFavoredNation,
            currency: formData.currency,
            minimum_investment: formData.minimumInvestment,
            board_approval_date: formData.boardApprovalDate,
            stockholder_approval_date: formData.stockholderApprovalDate,
            consideration_text: formData.considerationText,
            pro_rata_percentage: formData.proRataPercentage,
            investment_deadline: formData.investmentDeadline,
            contract_address: contractAddress,
            seniority: formData.seniority,
            uri: formData.uri,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Navigate to next page or show confirmation
      navigate(`/offerings/${offeringId}`, {
        state: offering,
      });
    } catch (error: any) {
      console.error('Error updating SAFE terms:', error);
      setError(
        error?.response?.data?.message || 'Failed to update SAFE terms.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offeringId && !offering) fetchOffering(offeringId);
  }, [offeringId, fetchOffering, offering]);

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>Edit SAFE Offering Terms</h2>
          <Form onSubmit={handleSubmit}>
            {loading && <p>Updating SAFE terms...</p>}

            {/* Discount */}
            <Form.Group as={Row} className="mb-3" controlId="formDiscount">
              <Form.Label column sm={2}>
                Discount (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Valuation Cap */}
            <Form.Group as={Row} className="mb-3" controlId="formValuationCap">
              <Form.Label column sm={2}>
                Valuation Cap
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="valuationCap"
                  value={formData.valuationCap}
                  onChange={handleFormChange}
                  required
                />
                <Form.Text className="text-muted">
                  Enter 0 if there is no valuation cap.
                </Form.Text>
              </Col>
            </Form.Group>

            {/* Most Favored Nation */}
            <Form.Group as={Row} className="mb-3" controlId="formMFN">
              <Form.Label column sm={2}>
                Most Favored Nation
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="checkbox"
                  name="mostFavoredNation"
                  checked={formData.mostFavoredNation}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Investment Token */}
            <Form.Group as={Row} className="mb-3" controlId="formCurrency">
              <Form.Label column sm={2}>
                Investment Token Address
              </Form.Label>
              <Col sm={10}>
                <TokenInputDropdown
                  recommendedTokens={recommendedTokens}
                  value={formData.currency}
                  onChange={(value) =>
                    setFormData({ ...formData, currency: value })
                  }
                />
              </Col>
            </Form.Group>

            {/* Minimum Investment */}
            <Form.Group as={Row} className="mb-3" controlId="minimumInvestment">
              <Form.Label column sm={2}>
                Minimum Investment Amount
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="minimumInvestment"
                  value={formData.minimumInvestment}
                  onChange={handleFormChange}
                  placeholder="Enter minimum investment amount"
                />
              </Col>
            </Form.Group>

            {/* Investment Deadline */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formInvestmentDeadline"
            >
              <Form.Label column sm={2}>
                Investment Deadline
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="investmentDeadline"
                  value={formData.investmentDeadline}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Board Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formBoardApprovalDate"
            >
              <Form.Label column sm={2}>
                Board Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="boardApprovalDate"
                  value={formData.boardApprovalDate}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Stockholder Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formStockholderApprovalDate"
            >
              <Form.Label column sm={2}>
                Stockholder Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="stockholderApprovalDate"
                  value={formData.stockholderApprovalDate}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Consideration Text */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formConsiderationText"
            >
              <Form.Label column sm={2}>
                Consideration Text
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  name="considerationText"
                  rows={3}
                  placeholder="Describe the consideration for the SAFE"
                  value={formData.considerationText}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Pro Rata Percentage */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formProRataPercentage"
            >
              <Form.Label column sm={2}>
                Pro Rata Rights (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="proRataPercentage"
                  value={formData.proRataPercentage}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Seniority */}
            <Form.Group as={Row} className="mb-3" controlId="formSeniority">
              <Form.Label column sm={2}>
                Seniority
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="seniority"
                  value={formData.seniority}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* URI */}
            <Form.Group as={Row} className="mb-3" controlId="formURI">
              <Form.Label column sm={2}>
                Metadata URI
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="uri"
                  placeholder="Enter metadata URI"
                  value={formData.uri}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Save Changes'}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingSafeTerms;

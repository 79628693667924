// VestingSchedule.tsx

import React, { useEffect, useState } from 'react';
import { useChainId, usePublicClient } from 'wagmi';
import { contracts, formatOptions } from '../../../constants';
import { Table, ProgressBar } from 'react-bootstrap';
import { formatDuration, intervalToDuration } from 'date-fns';
import numbro from 'numbro';
import humanizeDuration from 'humanize-duration';

interface VestingScheduleData {
  totalAmount: bigint;
  startDate: bigint;
  vestingDuration: bigint;
  cliffDuration: bigint;
}

export default function VestingSchedule({ certificate }: { certificate: any }) {
  const [vestingSchedule, setVestingSchedule] = useState<any>(null);
  const [vestedAmount, setVestedAmount] = useState<bigint>(BigInt(0));
  const publicClient = usePublicClient();
  const chainId = useChainId();

  function formatDurationInWords(seconds: number): string {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    return formatDuration(duration, { format: ['years', 'months', 'days'] });
  }

  useEffect(() => {
    (async () => {
      try {
        // Fetch the vesting schedule
        const [totalAmount, startDate, vestingDuration, cliffDuration] =
          (await publicClient?.readContract({
            address: contracts[chainId!].VestingModule.address as `0x${string}`,
            abi: contracts[chainId!].VestingModule.abi,
            functionName: 'getVestingSchedule',
            args: [certificate.token_id],
          })) as unknown as bigint[];

        const cliffDate = new Date(
          (Number(startDate) + Number(cliffDuration)) * 1000
        );

        const endDate = new Date(
          (Number(startDate) + Number(vestingDuration)) * 1000
        );

        // Format durations using date-fns
        const formattedVestingDuration = humanizeDuration(
          Number(vestingDuration) * 1000,
          { largest: 2 }
        );

        const formattedCliffDuration = humanizeDuration(
          Number(cliffDuration) * 1000,
          { largest: 2 }
        );

        const schedule: VestingScheduleData = {
          totalAmount: totalAmount,
          startDate: startDate,
          vestingDuration: vestingDuration,
          cliffDuration: cliffDuration,
        };

        setVestingSchedule(schedule);

        // Fetch current vested amount
        const vestedResult = await publicClient?.readContract({
          address: contracts[chainId!].VestingModule.address as `0x${string}`,
          abi: contracts[chainId!].VestingModule.abi,
          functionName: 'getVestedAmount',
          args: [Number(certificate.token_id)],
        });

        const currentVested = vestedResult as unknown as bigint;
        setVestedAmount(currentVested);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [certificate.token_id, chainId, publicClient]);

  if (!vestingSchedule) {
    return <div>Loading vesting schedule...</div>;
  }

  // If there's no vesting schedule (totalAmount is 0), display fully vested message
  if (vestingSchedule.totalAmount === BigInt(0)) {
    return (
      <div>
        <h4>Vesting Schedule</h4>
        <p>All shares are fully vested.</p>
      </div>
    );
  }

  // Calculate dates and current time
  const now = Date.now() / 1000; // Convert to seconds for comparison

  // Assuming the startDate should be when the certificate was issued
  const startDate = new Date(Number(vestingSchedule.startDate) * 1000);

  const cliffDate = new Date(
    startDate.getTime() + Number(vestingSchedule.cliffDuration) * 1000
  );

  const endDate = new Date(
    startDate.getTime() + Number(vestingSchedule.vestingDuration) * 1000
  );

  // Progress calculation - use the actual vested amount from contract
  const progressPercent = Number(
    (Number(vestedAmount) * 100) / Number(vestingSchedule.totalAmount)
  );

  // Determine if the vesting is in progress
  const isVestingInProgress = progressPercent > 0 && progressPercent < 100;

  // Determine if the vesting is complete
  const isVestingComplete = progressPercent >= 100;

  // Calculate key vesting milestones
  const milestones = [
    {
      period: 'Start',
      date: startDate,
      amount: 0,
      isMet: now >= startDate.getTime() / 1000,
    },
    {
      period: 'Cliff',
      date: cliffDate,
      amount:
        Number(vestingSchedule.cliffDuration) > 0
          ? Number(vestingSchedule.totalAmount) * 0.25 // Assuming 25% at cliff
          : 0,
      isMet: now >= cliffDate.getTime() / 1000,
    },
    {
      period: 'End',
      date: endDate,
      amount: Number(vestingSchedule.totalAmount),
      isMet: now >= endDate.getTime() / 1000,
    },
  ];

  return (
    <div>
      <h4>Vesting Schedule</h4>

      {/* Summary Section */}
      <h5>Summary</h5>
      <Table bordered>
        <tbody>
          <tr>
            <th>Vesting Start</th>
            <td>{startDate.toLocaleDateString()}</td>
          </tr>
          {vestingSchedule.cliffDuration > BigInt(0) && (
            <tr>
              <th>Cliff Date</th>
              <td>{cliffDate.toLocaleDateString()}</td>
            </tr>
          )}
          <tr>
            <th>Fully Vested Date</th>
            <td>{endDate.toLocaleDateString()}</td>
          </tr>
          <tr>
            <th>Total Amount</th>
            <td>
              {numbro(Number(vestingSchedule.totalAmount)).format(
                formatOptions
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      {/* Progress Section */}
      <h5>Progress</h5>
      <p>
        {numbro(Number(vestedAmount)).format(formatOptions)} of{' '}
        {numbro(Number(vestingSchedule.totalAmount)).format(formatOptions)}{' '}
        shares ({progressPercent.toFixed(2)}%) have vested
      </p>
      <ProgressBar
        now={progressPercent}
        label={`${progressPercent.toFixed(2)}%`}
        variant={isVestingComplete ? 'success' : undefined}
        animated={isVestingInProgress}
        striped={isVestingInProgress}
      />

      {/* Vesting Table */}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Milestone</th>
            <th>Date</th>
            <th>Shares Vested</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {milestones.map((milestone) => (
            <tr
              key={milestone.period}
              style={{ color: milestone.isMet ? '#0d6efd' : 'inherit' }}
            >
              <td>{milestone.period}</td>
              <td>{milestone.date.toLocaleDateString()}</td>
              <td>{numbro(Number(milestone.amount)).format(formatOptions)}</td>
              <td>{milestone.isMet ? 'Met' : 'Pending'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

// CertificateListItem.tsx

import React, { useState } from 'react';
import numbro from 'numbro';
import { formatOptions } from '../../constants';
import CertificateModal from './CertificateModal';

interface CertificateListItemProps {
  certificate: any;
}

export default function CertificateListItem({
  certificate,
}: CertificateListItemProps) {
  const {
    quantity,
    available_quantity,
    issuer_short_name,
    share_class_name,
    logoUrl,
    issue_date,
    cost_basis,
    price_per_share,
    label,
    tokenAddress,
    txHash,
    security_id,
  } = certificate;

  const [showModal, setShowModal] = useState(false);

  function parseAmountWithCurrency(value: string) {
    const match = value?.match(/\(([\d.]+),(\w+)\)/);
    if (match) {
      const amount = parseFloat(match[1]);
      const currency = match[2];
      return { amount, currency };
    }
    return { amount: 0, currency: 'USD' }; // default fallback
  }

  function formatCurrency(amount: number, currency: string) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 10,
    }).format(amount);
  }

  const parsedCostBasis = parseAmountWithCurrency(cost_basis);
  const parsedPricePerShare = parseAmountWithCurrency(price_per_share);

  return (
    <>
      <section
        className="card my-4 shadow-sm"
        onClick={() => setShowModal(true)}
        style={{ cursor: 'pointer' }}
      >
        <div className="d-flex align-items-center card-body p-4">
          <div className="col-1">
            <img
              className="img-fluid border rounded-circle"
              width="50"
              src={logoUrl}
              alt="Corporate Logo"
            />
          </div>
          <div className="col-8">
            <h5>
              {issuer_short_name}{' '}
              <small className="text-muted">{share_class_name}</small>
            </h5>
            <div>
              {label} <small className="text-muted">{security_id}</small>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-start text-end">
            <div>
              <div>
                {quantity !== undefined
                  ? numbro(quantity).format(formatOptions)
                  : '-'}{' '}
                {certificate.membership_class_id ? 'units' : 'shares'}
                <div className="text-muted" style={{ fontSize: '0.8em' }}>
                  {formatCurrency(
                    Number(quantity) * Number(parsedPricePerShare.amount),
                    parsedPricePerShare.currency
                  )}
                </div>
                {available_quantity < quantity && (
                  <div className="text-muted" style={{ fontSize: '0.8em' }}>
                    Available:{' '}
                    {numbro(available_quantity).format(formatOptions)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between text-secondary">
            <small>
              <span>
                Issue Date: {new Date(issue_date).toLocaleDateString()}
              </span>
            </small>
            <small>
              <span>
                Price Per Share:{' '}
                {formatCurrency(
                  parsedPricePerShare.amount,
                  parsedPricePerShare.currency
                )}
              </span>
            </small>
          </div>
        </div>
      </section>

      {/* Modal */}
      <CertificateModal
        show={showModal}
        onHide={() => setShowModal(false)}
        certificate={certificate}
      />
    </>
  );
}

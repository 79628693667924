import axios from 'axios';
import classnames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';

type Account = {
  account_id: string;
  bank_account_id: string;
  bank_name: string;
  account_name: string;
  account_mask: string;
};

export default function SelectBankAccount({
  selectedAccount,
  setSelectedAccount,
}: {
  selectedAccount: Account;
  setSelectedAccount: (account: Account) => void;
}) {
  const { auth } = useAuth();
  const { setError } = useError();
  const [linkToken, setLinkToken] = React.useState(null);
  const [expiration, setExpiration] = React.useState(null);
  const [externalAccounts, setExternalAccounts] = React.useState([]);
  const [externalAccountsLoading, setExternalAccountsLoading] =
    React.useState(true);
  console.log('selectedAccount', selectedAccount);
  React.useEffect(() => {
    (async () => {
      await createLinkToken();
      await getExternalAccounts();
    })();
  }, []);

  const createPlaidAccessToken = React.useCallback(
    async (publicToken: any, metadata: any) => {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/plaid/exchange_public_token`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          public_token: publicToken,
          metadata,
        },
      });
    },
    [auth]
  );

  const getExternalAccounts = React.useCallback(async () => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/v1/bank_accounts`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'X-Account-Id': auth?.user.account_id,
      },
    });
    console.log('externalAccounts', response.data.result);
    setExternalAccounts(response.data.result);
    if (response.data.result.length)
      setSelectedAccount(response.data.result[0]);
    setExternalAccountsLoading(false);
  }, [auth]);

  /**
   * Log and save metadata and exchange public token
   */
  const onSuccess = React.useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      try {
        if (!metadata.accounts.length) {
          return alert('Unable to link account.');
        }
        setExternalAccountsLoading(true);
        await createPlaidAccessToken(public_token, metadata);
        await getExternalAccounts();
        setExternalAccountsLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [createPlaidAccessToken, getExternalAccounts, setExternalAccountsLoading]
  );

  const createLinkToken = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/plaid/create_link_token`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });
      setExpiration(response.data.expiration);
      setLinkToken(response.data.link_token);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const config: PlaidLinkOptions = {
    onSuccess: onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: linkToken,
  };
  const { open, ready } = usePlaidLink(config);

  const unlinkBankAccount = async (id: any) => {
    // await axios({
    //   url: `http://localhost:8080/v1/accounts/${user?.ledger_account_id}/external_accounts/${id}`,
    //   method: 'DELETE',
    //   headers: {
    //     Authorization: `Bearer ${auth?.token}`,
    //   },
    // });
    alert('This feature is not available yet');
    await getExternalAccounts();
  };

  return (
    <>
      <div className="card shadow-sm my-3">
        <div className="card-body p-5">
          <div className={classnames(['row'])}>
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label">
                Select Bank Account
                <br />
                <small className="form-text text-muted">
                  Choose a bank account to fund your investments
                </small>
              </label>
            </div>
          </div>
          <div>
            {externalAccountsLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: 200 }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div>
                <pre>
                  {externalAccounts.map((externalAccount: any, index) => {
                    const isSelected =
                      selectedAccount &&
                      selectedAccount.bank_account_id ===
                        externalAccount.bank_account_id;
                    return (
                      <Card
                        key={index}
                        className={classnames('my-2', {
                          'border-primary border-2': isSelected,
                        })}
                        onClick={() => setSelectedAccount(externalAccount)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Card.Body>
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                              <div className="d-flex flex-row align-items-center justify-content-center mb-2">
                                <h5 className="mb-0">
                                  {externalAccount.bank_name}
                                </h5>
                                <div className="ms-1 badge bg-primary">
                                  {externalAccount.verification_status}
                                </div>
                              </div>
                              {externalAccount.account_name}{' '}
                              {externalAccount.account_mask}
                              <br />
                              <small className="text-muted">
                                {externalAccount.account_type
                                  .charAt(0)
                                  .toUpperCase() +
                                  externalAccount.account_type.slice(1)}{' '}
                              </small>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </pre>
              </div>
            )}
            <button
              className="btn btn-outline-primary float-end"
              disabled={!ready}
              onClick={() => open()}
            >
              Link New Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

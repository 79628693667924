import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';

interface OperatingAgreementUploaderProps {
  onUploadSuccess: (data: any) => void;
}

const OperatingAgreementUploader: React.FC<OperatingAgreementUploaderProps> = ({
  onUploadSuccess,
}) => {
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [existingDocument, setExistingDocument] = useState<any>(null);
  const { setError } = useError();
  const { auth } = useAuth();

  // Fetch existing document on component mount
  useEffect(() => {
    const fetchExistingDocument = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/documents/operating_agreement`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setExistingDocument(response.data.result);
      } catch (error: any) {
        if (error.response && error.response.status !== 404) {
          console.error('Error fetching existing document:', error);
          setError(error);
        }
        // It's okay if the document doesn't exist yet
      }
    };

    fetchExistingDocument();
  }, [auth?.token]);

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file: File | undefined = event.target.files?.[0];

    if (!file) {
      setUploadStatus('No file selected.');
      setLoading(false);
      return;
    }

    if (file.type !== 'application/pdf') {
      setUploadStatus('Please select a valid PDF file.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('files', file);
    formData.append('type', 'Operating Agreement');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/documents/operating_agreement`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setUploadStatus('File uploaded successfully.');
      onUploadSuccess(response.data.result);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError(error);
      setUploadStatus('Error uploading file.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-3">
      <label className="form-label">Operating Agreement</label>
      {existingDocument && (
        <div className="alert alert-primary">
          <div>
            An existing document has been uploaded:
            <br />
            <strong>{existingDocument.document_title}</strong> (Version{' '}
            {existingDocument.document_version})
          </div>
          <div className="mt-2">
            <a
              href={existingDocument.document_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Document
            </a>
          </div>
        </div>
      )}
      <InputGroup>
        <FormControl
          type="file"
          accept="application/pdf"
          onChange={handleUpload}
          disabled={loading}
        />
        {loading && (
          <InputGroup.Text>
            <Spinner animation="border" size="sm" className="text-primary" />
          </InputGroup.Text>
        )}
      </InputGroup>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default OperatingAgreementUploader;

// capsign.com/src/pages/Equity/Drafts/StockForm.tsx

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, InputGroup, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import { useEntity } from '../../../contexts/EntityContext';
import axios from 'axios';
import ModuleDataForm from '../../Identities/ManageCertificateForm/ModuleDataForm';
import { ModuleData } from '../../../types/moduleData';

interface StockFormProps {
  formData: StockFormData;
  handleChange: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { name: string; value: any }
  ) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
}

interface StockFormData {
  // Recipient Information
  name: string;
  email: string;
  accountAddress: string;
  stakeholderType: string;
  relationship: string;
  stateOfResidency: string;
  employeeID: string;
  jobTitle: string;
  annualSalary: number;

  // Issuance Details
  shareClass: string;
  quantity: number;
  cashPaid: number;
  pricePerShare: number;
  paymentHash: string;
  boardApproval: string;
  issueDate: string;
  rule144Date: string;
  vestingStartDate: string;
  accelerationTerms: string;
  buildLegend: string;
  federalExemption: string;
  stateExemption: string;
  valueOfIPTransferred: number;
  otherConsideration: string;
  rule144DifferenceReason: string;

  // Vesting Data
  moduleData: ModuleData | null;

  // Additional Information
  file: File | null;
  notes: string;
  startDate: string;
  cliffDuration: string;
  vestingDuration: string;
  vestingInterval: string;
  eventIds: string[];
  vestedAmounts: string[];
}

const relationshipOptions = [
  'Investor',
  'Founder',
  'Advisor',
  'Ex-Advisor',
  'Board member',
  'Consultant',
  'Ex-Consultant',
  'Employee',
  'Ex-Employee',
  'Executive',
  'International Employee',
  'Officer',
  'Other',
];

const accelerationTerms = [
  'No acceleration',
  // Add other options if necessary
];

const federalExemptions = [
  'Rule 701',
  'Section 4(a)(2)',
  'Section 4(a)(1-1/2)',
  'Section 4(a)(7)',
  'Rule 144',
  'Reg D - 506(b)',
  'Reg D - 506(c)',
  'Reg D - 506',
  'Reg D - 505',
  'Reg D - 504',
  'Reg S',
  'Reg A (Tier 1)',
  'Reg A (Tier 2)',
  'Reg CF',
  'Non-U.S.',
  'Other',
];

const currencyOptions = [
  'USD',
  'AUD',
  'ARS',
  'BRL',
  'BGN',
  'CAD',
  'CLP',
  'CNY',
  'COP',
  'HRK',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'IDR',
  'INR',
  'ISK',
  'JPY',
  'KES',
  'KRW',
  'MXN',
  'MYR',
  'MUR',
  'NOK',
  'ILS',
  'RON',
  'ANG',
  'NZD',
  'PHP',
  'PLN',
  'GBP',
  'RUB',
  'SGD',
  'ZAR',
  'SAR',
  'SEK',
  'CHF',
  'THB',
  'VND',
];

const StockForm: React.FC<StockFormProps> = ({
  formData,
  handleChange,
  handleSubmit,
  loading,
}) => {
  const { auth } = useAuth();
  const { entity } = useEntity();
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [hasVesting, setHasVesting] = useState(false);

  // Fetch share classes
  useEffect(() => {
    const fetchShareClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/${entity.entity_id}/share_classes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setShareClasses(response.data.result);
      } catch (error) {
        console.error('Error fetching share classes:', error);
        // Handle error appropriately
      }
    };

    if (entity?.entity_id) {
      fetchShareClasses();
    }
  }, [entity]);

  // Handle module data change from ModuleDataForm
  const handleModuleDataChange = (data: ModuleData) => {
    handleChange({ name: 'moduleData', value: data });
  };

  // Calculate price per share whenever quantity or cashPaid changes
  useEffect(() => {
    const amount = parseFloat(formData.quantity?.toString());
    const cash = parseFloat(formData.cashPaid?.toString());
    if (amount > 0 && cash > 0) {
      const calculatedPrice = cash / amount;
      handleChange({
        target: { name: 'pricePerShare', value: calculatedPrice.toString() },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleChange({
        target: { name: 'pricePerShare', value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData.quantity, formData.cashPaid]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* Recipient Information */}
      <h4>Recipient Information</h4>

      <Form.Group controlId="name" className="mb-3">
        <Form.Label>
          Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formData.name}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Email */}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>
          Email <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          required
          value={formData.email}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Account Address */}
      <Form.Group controlId="accountAddress" className="mb-3">
        <Form.Label>
          Account Address <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="accountAddress"
          value={formData.accountAddress}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Stakeholder Type */}
      <Form.Group controlId="stakeholderType" className="mb-3">
        <Form.Label>
          Stakeholder Type <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="stakeholderType"
          required
          value={formData.stakeholderType}
          onChange={handleChange}
        >
          <option value="">Select Stakeholder Type</option>
          <option value="Individual">Individual</option>
          <option value="Non-individual">Non-individual</option>
        </Form.Select>
      </Form.Group>

      {/* Relationship */}
      <Form.Group controlId="relationship" className="mb-3">
        <Form.Label>
          Relationship <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="relationship"
          required
          value={formData.relationship}
          onChange={handleChange}
        >
          <option value="">Select Relationship</option>
          {relationshipOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* State of Residency */}
      <Form.Group controlId="stateOfResidency" className="mb-3">
        <Form.Label>State of Residency</Form.Label>
        <Form.Control
          type="text"
          name="stateOfResidency"
          value={formData.stateOfResidency}
          onChange={handleChange}
        />
      </Form.Group>

      {formData.relationship === 'Employee' && (
        <>
          {/* Employee ID */}
          <Form.Group controlId="employeeID" className="mb-3">
            <Form.Label>Employee ID</Form.Label>
            <Form.Control
              type="text"
              name="employeeID"
              value={formData.employeeID}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Job Title */}
          <Form.Group controlId="jobTitle" className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Annual Salary */}
          <Form.Group controlId="annualSalary" className="mb-3">
            <Form.Label>Annual Salary</Form.Label>
            <Form.Control
              type="number"
              name="annualSalary"
              value={formData.annualSalary}
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}

      {/* Issuance Details */}
      <h4 className="mt-4">Issuance Details</h4>

      {/* Share Class */}
      <Form.Group controlId="shareClass" className="mb-3">
        <Form.Label>
          Share Class <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="shareClass"
          required
          value={formData.shareClass}
          onChange={handleChange}
        >
          <option value="">Select Share Class</option>
          {shareClasses.map((shareClass) => (
            <option key={shareClass.class_id} value={shareClass.class_id}>
              {shareClass.class_name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Quantity */}
      <Form.Group controlId="quantity" className="mb-3">
        <Form.Label>
          Quantity <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="quantity"
          required
          value={formData.quantity}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Cash Paid */}
      <Form.Group controlId="cashPaid" className="mb-3">
        <Form.Label>Cash Paid</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="cashPaid"
            value={formData.cashPaid}
            onChange={handleChange}
          />
        </InputGroup>
      </Form.Group>

      {/* Price per Share */}
      <Form.Group controlId="pricePerShare" className="mb-3">
        <Form.Label>Price per Share</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="pricePerShare"
            value={formData.pricePerShare}
            readOnly
          />
        </InputGroup>
      </Form.Group>

      {/* Payment Hash */}
      <Form.Group controlId="paymentHash" className="mb-3">
        <Form.Label>Payment Hash</Form.Label>
        <Form.Control
          type="text"
          name="paymentHash"
          value={formData.paymentHash}
          onChange={handleChange}
        />
        <Form.Text className="text-muted">
          Transaction hash of the payment
        </Form.Text>
      </Form.Group>

      {/* Board Approval */}
      <Form.Group controlId="boardApproval" className="mb-3">
        <Form.Label>
          Board Approval <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="boardApproval"
          required
          value={formData.boardApproval}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Issue Date */}
      <Form.Group controlId="issueDate" className="mb-3">
        <Form.Label>
          Issue Date <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="issueDate"
          required
          value={formData.issueDate}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Include Vesting Conditions */}
      <Form.Group className="mb-3">
        <Form.Check
          type="switch"
          id="vesting-switch"
          label="Include Vesting Conditions"
          checked={hasVesting}
          onChange={(e) => setHasVesting(e.target.checked)}
        />
      </Form.Group>

      {/* Vesting Schedule */}
      {hasVesting && (
        <ModuleDataForm
          onChange={handleModuleDataChange}
          initialValues={formData.moduleData}
        />
      )}

      {/* Acceleration Terms */}
      <Form.Group controlId="accelerationTerms" className="mb-3">
        <Form.Label>Acceleration Terms</Form.Label>
        <Form.Select
          name="accelerationTerms"
          value={formData.accelerationTerms}
          onChange={handleChange}
        >
          {accelerationTerms.map((term, index) => (
            <option key={index} value={term}>
              {term}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Build Legend */}
      <Form.Group controlId="buildLegend" className="mb-3">
        <Form.Label>Build Legend</Form.Label>
        <Form.Select
          name="buildLegend"
          value={formData.buildLegend}
          onChange={handleChange}
        >
          <option value="Default">Default</option>
          <option value="RSAs legends">RSAs legends</option>
          <option value="Exercise legends">Exercise legends</option>
        </Form.Select>
      </Form.Group>

      {/* Federal Exemption */}
      <Form.Group controlId="federalExemption" className="mb-3">
        <Form.Label>
          Federal Exemption <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="federalExemption"
          required
          value={formData.federalExemption}
          onChange={handleChange}
        >
          <option value="">Select Federal Exemption</option>
          {federalExemptions.map((exemption, index) => (
            <option key={index} value={exemption}>
              {exemption}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* State Exemption */}
      <Form.Group controlId="stateExemption" className="mb-3">
        <Form.Label>State Exemption</Form.Label>
        <Form.Control
          type="text"
          name="stateExemption"
          value={formData.stateExemption}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Value of IP Transferred */}
      <Form.Group controlId="valueOfIPTransferred" className="mb-3">
        <Form.Label>Value of IP Transferred</Form.Label>
        <Form.Control
          type="number"
          name="valueOfIPTransferred"
          value={formData.valueOfIPTransferred}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Other Consideration */}
      <Form.Group controlId="otherConsideration" className="mb-3">
        <Form.Label>Other Consideration</Form.Label>
        <Form.Control
          type="text"
          name="otherConsideration"
          value={formData.otherConsideration}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Rule 144 Difference Reason */}
      <Form.Group controlId="rule144DifferenceReason" className="mb-3">
        <Form.Label>Rule 144 Difference Reason</Form.Label>
        <Form.Control
          type="text"
          name="rule144DifferenceReason"
          value={formData.rule144DifferenceReason}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Documents */}
      <Form.Group controlId="file" className="mb-3">
        <Form.Label>Documents</Form.Label>
        <Form.Control type="file" name="file" onChange={handleChange} />
      </Form.Group>

      {/* Notes */}
      <Form.Group controlId="notes" className="mb-3">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          name="notes"
          rows={3}
          value={formData.notes}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Submit'}
      </Button>
    </Form>
  );
};

export default StockForm;

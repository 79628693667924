import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useAccount,
  useConnect,
  useChainId,
  useWalletClient,
  useDisconnect,
} from 'wagmi';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import axios from 'axios';
import IdentityProxy from '@capsign/contracts/artifacts/contracts/identity/proxy/IdentityProxy.sol/IdentityProxy.json';
import Identity from '@capsign/contracts/artifacts/contracts/identity/Identity.sol/Identity.json';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { contracts } from '../../../constants';
import { useError } from '../../../contexts/ErrorContext';
import {
  encodeAbiParameters,
  keccak256,
  concat,
  Abi,
  pad,
  getCreate2Address,
} from 'viem';
import { base, baseSepolia } from 'wagmi/chains';
import walletIcon from './wallet.png';

// Same address for Base mainnet and Sepolia
const safeSingletonDeployer = '0x914d7fec6aac8cd542e72bca78b30650d45643d7';

export default function SelfCustodyWallet(props: any) {
  const [userIdentity, setUserIdentity] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const { setError } = useError();
  const navigate = useNavigate();
  const account = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { data: walletClient } = useWalletClient();
  const { writeContractsAsync: deployIdentityAndAddKey } = useWriteContracts();
  const chainId = useChainId();

  const { data: availableCapabilities } = useCapabilities({
    account: walletClient?.account?.address,
    connector: connectors.find(
      (connector: any) => connector.id === 'coinbaseWalletSDK'
    ),
  });

  // const capabilities = useMemo(() => {
  //   if (!availableCapabilities || !chainId) return {};
  //   const capabilitiesForChain = availableCapabilities[chainId];
  //   if (
  //     capabilitiesForChain?.paymasterService &&
  //     capabilitiesForChain.paymasterService.supported
  //   ) {
  //     return {
  //       paymasterService: {
  //         url: process.env.REACT_APP_PAYMASTER_URL,
  //       },
  //     };
  //   }
  //   return {};
  // }, [availableCapabilities, chainId]);

  const capabilities = useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    [process.env.REACT_APP_PAYMASTER_URL]
  );

  /**
   * Set user identity
   */
  useEffect(() => {
    const identityAddress =
      props.onboarding?.entity?.identity?.identity_address;
    if (identityAddress) setUserIdentity(identityAddress);
  }, [props.onboarding]);

  /**
   * @function connectWallet
   */
  const connectWallet = useCallback(() => {
    try {
      const coinbaseWalletConnector = connectors.find(
        (connector: any) => connector.id === 'coinbaseWalletSDK'
      );
      if (coinbaseWalletConnector) {
        connect({
          connector: coinbaseWalletConnector,
          chainId:
            process.env.NODE_ENV === 'development' ? baseSepolia.id : base.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [connectors, connect]);

  const disconnectWallet = useCallback(() => {
    disconnect();
  }, []);

  /**
   * @function computeAddress
   * @param bytecode
   * @param args
   * @returns
   */
  const computeAddress = async (bytecode: `0x${string}`, args: any[]) => {
    const constructorAbi = [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_implementationAuthority',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'initialManagementKey',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
    ];

    const deployData = encodeAbiParameters(constructorAbi[0].inputs, args);
    const fullBytecode = concat([bytecode, deployData]);
    const salt = keccak256(new TextEncoder().encode(Date.now().toString()));

    const computedAddress = getCreate2Address({
      from: safeSingletonDeployer,
      salt,
      bytecode: fullBytecode,
    });

    return {
      computedAddress,
      salt,
      fullBytecode,
    };
  };

  const createCallData = (
    salt: `0x${string}`,
    fullBytecode: `0x${string}`
  ): `0x${string}` => {
    const saltHex = pad(salt, { size: 32 });
    return concat([saltHex, fullBytecode]);
  };

  /**
   * @function createIdentity
   * @returns {void}
   */
  const createIdentity = async (): Promise<void> => {
    if (!walletClient) return;

    try {
      setLoading(true);
      const initialManagementKey = walletClient.account.address;
      const identityImplementationAuthorityAddress = contracts[chainId]
        .IdentityImplementationAuthority.address as `0x${string}`;

      if (!initialManagementKey || !identityImplementationAuthorityAddress) {
        throw new Error('Invalid addresses');
      }

      const args = [
        identityImplementationAuthorityAddress,
        initialManagementKey,
      ];

      const computedAddressResult = await computeAddress(
        IdentityProxy.bytecode as `0x${string}`,
        args
      );

      const callData = createCallData(
        computedAddressResult.salt,
        computedAddressResult.fullBytecode
      );

      const keyHash = keccak256(
        encodeAbiParameters([{ type: 'address' }], [initialManagementKey])
      );

      const deployResults = await deployIdentityAndAddKey({
        contracts: [
          {
            abi: [
              {
                inputs: [
                  { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                name: 'call',
                outputs: [
                  { internalType: 'bool', name: 'success', type: 'bool' },
                ],
                stateMutability: 'nonpayable',
                type: 'function',
              },
            ],
            functionName: 'call',
            args: [callData],
            address: safeSingletonDeployer,
          },
          {
            abi: Identity.abi as Abi,
            functionName: 'addKey',
            args: [keyHash, BigInt(2), BigInt(1)], // purpose: 2, keyType: 1
            address: computedAddressResult.computedAddress,
          },
        ],
        capabilities,
        account: walletClient.account,
        chainId,
      });

      await axios(`${process.env.REACT_APP_API_URL}/v1/identities`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-ID': auth?.user.account_id,
        },
        data: {
          wallet_address: initialManagementKey,
          identity_address: computedAddressResult.computedAddress,
          chain_id: chainId,
        },
      });

      setUserIdentity(computedAddressResult.computedAddress);
      navigate('/onboarding/verify-id');
    } catch (error: any) {
      console.error('Error deploying contract:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {account?.status === 'disconnected' ? (
        <div className="text-center border rounded p-4">
          <p>
            Please sign into a{' '}
            <a
              href="https://www.coinbase.com/wallet/smart-wallet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coinbase smart wallet
            </a>{' '}
            account or create a new one.
          </p>
          <div className="text-center">
            <button
              className="btn btn-primary btn-lg text-center"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          </div>
        </div>
      ) : (
        <>
          <img src={walletIcon} className="w-50" />
          {process.env.NODE_ENV === 'development' && (
            <div className="mb-5">
              <span className="border rounded p-3">
                Connected to chain ID: {chainId}
              </span>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="btn btn-primary btn-lg"
              onClick={createIdentity}
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                'Create Identity'
              )}
            </button>
          </div>

          <div className="mt-3">
            <button
              className="btn btn-secondary btn-lg"
              onClick={disconnectWallet}
              disabled={loading}
            >
              <i className="fas fa-right-from-bracket"></i> Disconnect
            </button>
          </div>
        </>
      )}
    </div>
  );
}

import React, { createContext, useContext, useState, ReactNode } from 'react';

type AuthContextType = {
  auth: {
    user: {
      account_id: string;
      identity_address: string;
      email: string;
      id: string;
      roles: string[];
    };
    token: string;
  } | null;
  setAuth: (token: string | null) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    // Local storage variables are prefixed with capsign__ and are snake case
    const value = localStorage.getItem(`capsign__auth_${process.env.NODE_ENV}`);
    const initialValue = JSON.parse(value!);
    return initialValue || '';
  });

  /**
   * Save auth token to local storage
   */
  React.useEffect(() => {
    (async () => {
      if (auth) {
        try {
          localStorage.setItem(
            `capsign__auth_${process.env.NODE_ENV}`,
            JSON.stringify(auth)
          );
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [auth]);

  const value = { auth, setAuth };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

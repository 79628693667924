import React, { useState, useEffect } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { contracts } from '../../constants';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';
import { useChainId, usePublicClient, useWriteContract } from 'wagmi';
import { Abi } from 'viem';

function RegisterContract() {
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { tokenId } = useParams();
  const [contractAddress, setContractAddress] = useState('');
  const { auth } = useAuth();
  const [identityRegistryAddress, setIdentityRegistryAddress] =
    useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { setError } = useError();
  const navigate = useNavigate();

  /**
   * Fetch token
   */
  useEffect(() => {
    (async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        let result = response.data.result;
        const _token = result[0];

        const _identityRegistry = await publicClient?.readContract({
          address: tokenId as `0x${string}`,
          abi: contracts[chainId!].Stock.abi as Abi,
          functionName: 'identityRegistry',
        });

        setIdentityRegistryAddress(_identityRegistry as `0x${string}`);
      } catch (error: any) {
        console.error(error);
        setError(error);
      }
    })();
  }, [auth?.token, chainId, publicClient, tokenId, setError]);

  const { writeContractAsync: addAllowedContract } = useWriteContract();

  const register = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (!chainId || !contractAddress) {
        alert('Please check your input.');
        setLoading(false);
        return;
      }

      const tx = await addAllowedContract({
        address: identityRegistryAddress as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi as Abi,
        functionName: 'addAllowedContract',
        args: [contractAddress],
        gas: BigInt(10_000_000),
      });

      await publicClient?.waitForTransactionReceipt({ hash: tx });

      alert('Contract registered successfully.');
      setContractAddress('');
      navigate(-1);
    } catch (error: any) {
      console.error('Registration failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}`}>{tokenId}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}/identities`}>Registry</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Register Contract
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Card className="col-4 offset-4 mt-5">
        <Card.Header>
          <h4 className="mb-0">Register Contract</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={register}>
            <Form.Group className="mb-3" controlId="contractAddress">
              <Form.Label>Contract Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contract address"
                value={contractAddress}
                onChange={(e) => setContractAddress(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Register Contract'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RegisterContract;
